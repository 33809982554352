import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { isFirefox, isIOS } from 'react-device-detect';
import { RequestCamera } from '../utils/permission';

import { AlertDialog } from '../components/dialog';

import { SupportsWebGL } from "../utils/supportsWebgl";


const useWebgl = SupportsWebGL();


// ----------------------------------------------------------------------

const StyledContent = styled('div')(() => ({
  boxSizing: 'border-box',
  margin: 0,
  flexDirection: 'row',
  flexBasis: '100%',
  flexGrow: '0',
  maxWidth: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'unset',
  alignItems: 'center',
  padding: '0 !important'
}));

export default function PermissionsPage() {
  const [geolocationPermission, setGeolocationPermission] = useState('prompt');
  const [cameraPermission, setCameraPermission] = useState('prompt');
  const [deviceOrientationPermission, setDeviceOrientationPermission] = useState('prompt');

  const [permissionDialogState, SetPermissionDialogState] = useState(false); // denied permission Dialog
  const [permissionSkipState, SetPermissionSkipState] = useState(false); // skip permission Dialog

  const navigate = useNavigate();

  const CheckPermission = useCallback((permissionName, descriptor) => {
    try {
      navigator.permissions.query({ name: permissionName, ...descriptor })
        .then((permission) => {
          SetPermission(permissionName, permission.state);
          permission.onchange = () => {
            SetPermission(permissionName, permission.state);
          };
        }).catch((e) => {
          console.log(e);
          SetPermission(permissionName, 'unsupported');
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    CheckPermission("geolocation");
    if (isFirefox) {
      SetPermission('camera', 'unknown');
    }
    else {
      CheckPermission("camera");
    }

  }, [CheckPermission]);

  function SetPermission(permissionName, state) {
    switch (permissionName) {
      case "geolocation":
        setGeolocationPermission(state);
        break;
      case "camera":
        setCameraPermission(state);
        break;
      case "deviceorientation":
        setDeviceOrientationPermission(state);
        break;
      default:
        break;
    }
  }

  function TranslatedPermissionState(permissionState) {
    switch (permissionState) {
      case "granted":
        return "geaccepteerd"
      case "unknown":
        return "onbekend"
      case "denied":
        return "geweigerd"
      case "unsupported":
        return "niet ondersteund"
      case "prompt":
        return "vraag toestemming"  
      default:
        return "onbekend"
    }
  }

  function RequestCameraPermission() {
    if (cameraPermission === 'denied') {
      SetPermissionDialogState(true)
    } else  {
      RequestCamera( 
        () => SetPermission('camera', 'granted'), 
        () => SetPermission('camera', 'denied'),
        () => SetPermission('camera', 'unsupported'),
      );
    }
  }

  // Device Orientation
  const RequestDeviceOrientationPermission = useCallback(() => {
    if (window.location.protocol !== "https:") {
      console.log('not secure');
      return;
    }

    if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceMotionEvent.requestPermission) === "function") {
      DeviceMotionEvent.requestPermission()
        .then(response => {
          SetPermission('deviceorientation', response)
        })
        .catch(console.error)
    } else {
      console.log("DeviceMotionEvent is not defined");
    }
  }, []);



  // Location
  function RequestLocationPermission() {
    if (geolocationPermission === 'denied') {
      SetPermissionDialogState(true)
    } else {
      navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);
    }
  }

  const onLocationFetchSuccess = () => {
    setGeolocationPermission('granted');
  };

  const onLocationFetchFailure = (error = {}) => {
    // Error code 1 corresponds to user denying/blocking the location permission
    setGeolocationPermission('denied');
    if (error.code === 1) {
      // Respond to failure case as required
    }
  };

  return (
    <>
      <Helmet>
        <title> Toestemmingen </title>
      </Helmet>

      <StyledContent>
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', py: 'clamp(24px, 5%, 112px)'}}>
          <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={6} textAlign="left" alignItems='center'>
            
            <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              
              <Stack alignItems='center' spacing={2}>
                
                <Typography variant="h1" sx={{fontSize: 'clamp(2rem, 2rem + 3vw, 2.8125rem)', textAlign: 'center'}}>
                  Apparaat toestemmingen
                </Typography>
                <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', textAlign: 'center', maxWidth: '600px' }}>
                Deze web app gebruikt onder andere je locatie en camera. Voor de beste gebruikerservaring raden we aan om alle onderstaande toestemmingen te accepteren.
                </Typography>

              </Stack>

            </Container>


            <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexDirection: 'column', px: '16px' }}>
              
              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '12px', borderRadius: '8px' }}>
                
                <Stack sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', m: '8px', width: '230px' }}>
                  
                  <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)' }}>
                    Locatie:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)',  color: geolocationPermission === 'granted' ? '#50994B' : '#CC5652' }}>
                    {TranslatedPermissionState(geolocationPermission)}
                  </Typography>

                </Stack>

                <Button disabled={geolocationPermission === 'granted' || geolocationPermission === 'unsupported'} onClick={() => RequestLocationPermission()} variant="text" sx={{m: '0px'}}>
                  Toestemming vragen
                </Button>

              </Stack>


              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '12px', borderRadius: '8px' }}>
                
                <Stack sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', m: '8px', width: '230px' }}>
                  
                <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)' }}>
                    Camera:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: cameraPermission === 'granted' ? '#50994B' : '#CC5652'  }}>
                    {TranslatedPermissionState(cameraPermission)}
                  </Typography>
                </Stack>
                  
                <Button disabled={cameraPermission === 'granted' || cameraPermission === 'unsupported'} onClick={() => RequestCameraPermission()} variant="text" sx={{m: '0px'}}>
                  Toestemming vragen
                </Button>

              </Stack>


              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '12px', borderRadius: '8px' }}>

                <Stack sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', m: '8px', width: '230px' }}>

                <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)' }}>
                    Orientatie:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: (!isIOS || deviceOrientationPermission === 'granted') ? '#50994B' : '#CC5652' }}>
                    {isIOS ? TranslatedPermissionState(deviceOrientationPermission) : 'geaccepteerd'}
                  </Typography>

                </Stack>
                
                {isIOS ?
                  <Button disabled={deviceOrientationPermission === 'granted' || deviceOrientationPermission === 'unsupported'} onClick={() => { RequestDeviceOrientationPermission(); }} variant="text" sx={{m: '0px'}} >
                    Toestemming vragen
                  </Button>
                  :
                  <Button disabled variant="text" sx={{m: '0px'}}>
                    Toestemming vragen
                  </Button>
                }

              </Stack>

              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '12px', borderRadius: '8px' }}>
                
                <Stack sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', m: '8px', width: '230px' }}>
                  
                <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)' }}>
                    WebGL:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: useWebgl ? '#50994B' : '#CC5652'  }}>
                  {useWebgl ? "Toegestaan" : "Niet toegestaan"}
                  </Typography>
                </Stack>
              </Stack>

            </Container>


            <Container sx={{display: 'flex', flexDirection: 'column', maxWidth: '480px !important', alignSelf: 'center'}}>
              <Button onClick={() => { navigate('/', { replace: true }); }} fullWidth sx={{ mb: '12px' }} variant="contained">
                Terug
              </Button>
            </Container>


          </Stack>
        </Box>
      </StyledContent>

      {/* exit dialog */}
      <AlertDialog open={permissionDialogState} handleClose={() => SetPermissionDialogState(false)} title="Toestemming geweigerd"
        content="Deze toestemming is geweigerd. Ga naar je browserinstellingen om deze toestemming aan te passen."
        primaryButtonText="Ik begrijp het" secondaryButtonText=""
        primaryButtonFunc={() => { }} secondaryButtonFunc={() => { }} />

      {/* skip dialog */}
      <AlertDialog open={permissionSkipState} handleClose={() => SetPermissionSkipState(false)} title="Overslaan"
        content="Mogelijk werken sommige van de applicatie niet. Weet je zeker dat je verder wilt gaan?"
        primaryButtonText="Verder" secondaryButtonText="Terug"
        primaryButtonFunc={() => { }} secondaryButtonFunc={() => { }} />
    </>
  );
}
