import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Button, Typography, Container, Box, Stack, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { QR } from '../components/qr-reader';
import { ButtonsContainer } from '../components/content';

// ----------------------------------------------------------------------
// Desired QR format   domain/qr/:id (opoorlogspad.nl/qr/2)
// ----------------------------------------------------------------------

export default function QRPage() {

    const isCompactHeight = useMediaQuery('(min-width:1200px)');
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title> Scan QR </title>
            </Helmet>

            <Grid sx={{ height: isCompactHeight ? '66vh' : 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', justifyContent: 'unset', alignItems: 'center' }} item xs={12}>
                <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', py: '24px' }}>
                    <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={2} textAlign="left" alignItems='center'>
                        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <Stack alignItems='center' spacing={2}>
                                <Typography variant="h1">
                                    Scan de QR-code
                                </Typography>
                                <Typography variant="body1" sx={{ textAlign: 'center' }} maxWidth={'600px'} >
                                    Vind en scan de QR-code van de route.
                                </Typography>
                            </Stack>
                        </Container>
                        <Container sx={{ alignItems: 'center', margin: 'auto', display: 'flex', maxWidth: '600px !important', p: '0px !important', justifyContent: 'center', position: 'relative' }}>
                            <QR onScan={(id) => {
                                // const newUrl = `${window.location.href.substring(0, window.location.href.indexOf('/qr'))}/route/${id}/content`;
                                navigate(`../route/${id}/content`, {state: {reset: true}});
                            }} />
                        </Container>

                        <ButtonsContainer>
                            <Button sx={{ mb: '12px' }} to="/" variant="contained" component={RouterLink}>
                                Terug
                            </Button>
                        </ButtonsContainer>
                    </Stack>
                </Box>
            </Grid>
        </>
    );
}

