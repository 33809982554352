import { ContentType } from "./contentTypes";

export const routes = [

  // OOSTERBEEK -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 1,
    name: 'Oosterbeek',
    cover: 'assets/oosterbeek/images/Oude_kerk_1944.jpg',
    category: 0,
    city: 'Oosterbeek',
    street: 'Benedendorpsweg 134',
    zip: '6862 WP',
    length: 500,
    position: {
      lat: 51.978205,
      lng: 5.837598,
    },
    description: 'Op 20 september 1944 geeft majoor Lonsdale in de Oude Kerk, een indringende speech aan zijn afgepeigerde mannen. In deze korte wandeling maak je kennis met de Lonsdale Force en volg je de White Ribbon Mile rondom de kerk.',
    content: [

      // Oosterbeek - Locatie 1

      {
        id: 101,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/oosterbeek/images/richard-lonsdale-1024.png',
            thumbnail: 'assets/oosterbeek/images/richard-lonsdale-gevonden-1024.png',
            title: 'Majoor Richard Lonsdale',
            text: '',
          },
        ],
        title: 'Voorwerp gevonden!',
        text: 'Je hebt een foto gevonden van Richard Lonsdale. Hij is een van de hoofdpersonen op dit Oorlogspad.',
      },
      {
        id: 102,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
      //  url: 'assets/oosterbeek/videos/Video 1_HB.mp4',
        url: 'assets/oosterbeek/videos/Video 1+2_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar de maand september in het jaar 1944.',
      },
      /*
      {
        id: 103,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Situatie',
        url: 'assets/oosterbeek/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de situatie in 1944: de Britse perimeter in de vorm van een hoefijzer.',
      },
      */
      {
        id: 104,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [10401, 10402, 10403],
        title: 'Keuzemenu',
        text: 'Maak kennis met de hoofdrolspelers.',
      },
      {
        id: 105,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.977982, lng: 5.838151 },
        range: 12,
        text: 'Loop links om de kerk heen.',
      },

      // Oosterbeek - Locatie 2

      {
        id: 106,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Je hebt de deur gevonden waar Lonsdale zijn speech op schreef in houtskool! Hij gebruikte de deur als spiekbriefje tijdens de filmopnamen in 1945 want hij kon zijn tekst niet onthouden. De echte deur kun je bekijken in het Airborne Museum.',
        model: 'assets/oosterbeek/models/door6.glb',
        poster: 'assets/oosterbeek/images/deur-thumbnail-1024.png',
      },
      {
        id: 107,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Speech',
        url: 'assets/oosterbeek/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: "Samir neemt je mee terug naar woensdag 20 september 1944. Beelden 'Theirs is the Glory' | All rights reserved - ITV Global Distribution LTD",
      },
      {
        id: 108,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/oosterbeek/images/oosterbeek-oude-kerk-toen-16-9.jpg',
        outlineImage: 'assets/oosterbeek/images/oosterbeek-oude-kerk-toen-16-9.jpg',
        title: 'Toen en nu',
        text: "Wil je weten hoe de kerk eruitzag in die tijd? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts.",
      },
      {
        id: 109,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.977887, lng: 5.836926 },
        range: 15,
        text: 'Loop verder achter de kerk langs. Volg je kompas.',
      },

      // Oosterbeek - Locatie 3

      {
        id: 110,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/oosterbeek/images/Verbandtrommel_1024.png',
            thumbnail: 'assets/oosterbeek/images/verbandtrommel-gevonden-1024.png',
            title: 'Verbandtrommel',
            text: '',
          },
        ],
        title: 'Voorwerp gevonden!',
        text: 'Tik op de foto om het voorwerp te bekijken.',
      },
      {
        id: 111,
        type: ContentType.Video,
        thumbnail: '',
        title: 'First Aid',
        text: 'Bekijk de video.',
        url: 'assets/oosterbeek/videos/Video 4_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 112,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [11201, 11202, 11203],
        title: 'Keuzemenu',
        text: 'Maak kennis met andere hoofdrolspelers.',
      },
      {
        id: 113,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.977657, lng: 5.834661 },
        range: 15,
        text: 'Gebruik je kompas voor de volgende stop op dit Oorlogspad.',
      },

      // Oosterbeek - Locatie 4

      {
        id: 114,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Tik op het scherm om het voorwerp te bekijken.',
        model: 'assets/oosterbeek/models/TreasureBox_JPG.glb',
        poster: 'assets/oosterbeek/images/koffer-gevonden-1024.png',
      },
      {
        id: 115,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Aftocht',
        text: 'Tijd om te vertrekken.',
        url: 'assets/oosterbeek/videos/Video 5_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 116,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Marion Gerritsen_512.jpg',
        url: 'assets/oosterbeek/audio/Marion deel 2 in de kerk.mp3',
        title: 'Oude kerk',
        text: 'De soldaten verzamelen zich voor hun aftocht in de Oude Kerk. Gids Marion Gerritsen vertelt.',
      },
      {
        id: 117,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [11701, 11702, 11704],
        title: 'Keuzemenu',
        text: '',
      },
      {
        id: 118,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.978229, lng: 5.837342 },
        range: 15,
        text: 'Gebruik je kompas en loop terug naar het eindpunt van dit Oorlogspad.',
      },

      // Oosterbeek - Locatie 5

      {
        id: 119,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Bevrijding',
        text: 'Hoe is het daarna afgelopen met Oosterbeek?',
        url: 'assets/oosterbeek/videos/Video 7_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 120,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Marion Gerritsen_512.jpg',
        url: 'assets/oosterbeek/audio/Marion deel 3 bloemlegging.mp3',
        title: 'Bloemlegging',
        text: 'Beluister Marion Gerritsen over bloemlegging.',
      },
      {
        id: 121,
        type: ContentType.Video,
        thumbnail: '',
        title: 'De gevolgen',
        text: 'Hoe kijken we terug op Operatie Market Garden?',
        url: 'assets/oosterbeek/videos/Video 8_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 122,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [12201, 12202],
        title: 'Keuzemenu',
        text: 'Maak een foto en/of steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 123,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland!',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Oosterbeek | Multi Content ------------------------------------------------------------------------------------------

      {
        id: 10401,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Majoor Richard Lonsdale_512.jpg',
        url: 'assets/oosterbeek/audio/Londsdale telefoon.mp3',
        title: 'Majoor Richard Lonsdale',
        text: 'Het is in dit gebied waar je nu bent waar de Engelsen stand willen houden. Ze wachten op versterking van militairen van het XXX Corps, die via de grond komen. Maar het is totaal onduidelijk wanneer dat gaat gebeuren. Majoor Dickie Lonsdale vertelde daar het volgende over. Beluister het nagespeelde audiofragment.',
      },
      {
        id: 10402,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Marion Gerritsen_512.jpg',
        url: 'assets/oosterbeek/audio/Marion deel 1 evacuatie uit Oosterbeek.mp3',
        title: 'Marion Gerritsen',
        text: 'De inwoners van Oosterbeek hangen op 17 september 1944 de vlag uit wanneer ze de bevrijders zien, maar die blijdschap is van korte duur. De strijd moet nog beginnen met grote gevolgen voor de burgerbevolking. De ouders van Marion Gerritsen, gids in de Oude Kerk, hebben het meegemaakt...',
      },
      {
        id: 10403,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Duitse kolonel Werner Harzer_512.jpg',
        url: 'assets/oosterbeek/audio/Audio Harzer.mp3',
        title: 'Duitse kolonel Werner Harzer',
        text: 'De Duitse SS-kolonel Harzer blikt na de oorlog terug op de gevechten in Oosterbeek. Beluister het nagespeelde audiofragment.',
      },

      //---------------------------------------------------------------------------------------------------------

      {
        id: 11201,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Arts Randall Martin_512.jpg',
        url: 'assets/oosterbeek/audio/Dokter Randall.mp3',
        title: 'Arts Randall Martin',
        text: 'De grote pastorie is een van de belangrijke medische posten in de Oosterbeekse perimeter. De Engelse arts Randall Martin is hier werkzaam en bericht het volgende...',
      },
      {
        id: 11202,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/Gewonde soldaat_512.jpg',
        url: 'assets/oosterbeek/audio/Soldaat ziekenboeg.mp3',
        title: 'Gewonde soldaat',
        text: 'In de pastorie liggen vele gewonde soldaten, zelfs in de kleine kelderruimte waar de voorraad schappen dienen als bed.',
      },
      {
        id: 11203,
        type: ContentType.Video,
        thumbnail: 'assets/oosterbeek/images/Kate ter horst_512.jpg',
        title: 'Kate ter Horst',
        text: 'Kate ter Horst heeft net haar kinderen de tekst van Psalm 91 voorgedragen. Nu spreekt ze diezelfde woorden als troost tot de mannen:',
        url: 'assets/oosterbeek/videos/Kate Ter Horst + Subs_HB.mp4',
        videoThumbnail: '',
      },

      //---------------------------------------------------------------------------------------------------------

      {
        id: 11701,
        type: ContentType.Audio,
        thumbnail: 'assets/algemeen/placeholders/Samir_PLACEHOLDER.jpg', // placeholder past hier goed
        url: 'assets/oosterbeek/audio/Video 6.mp3',
        title: 'Aftocht',
        text: '25 september 1944 dag 9: hier vindt de evacuatie van de militairen plaats onder de naam Operatie Berlin, met hulp van Poolse parachutisten die aan de zuidkant van de rivier zijn geland. De mannen hebben alles gegeven. Op hun schoenen, omwikkeld met parachutelint, sluipen ze stilletjes langs een wit lint richting het water. Ze proberen te vluchten in bootjes. En als er geen bootjes meer zijn dan zullen ze moeten zwemmen. De gewonden blijven achter.',
      },
      {
        id: 11702,
        type: ContentType.Audio,
        thumbnail: 'assets/oosterbeek/images/evacuatie burgers.jpg',
        url: 'assets/oosterbeek/audio/Evacuatie kind concertzaal.mp3',
        title: 'Evacuatie burgers',
        text: 'Op dinsdag 26 september vertrekt een familie te voet en per fiets vanuit Oosterbeek naar het Friese Sneek. Ze moeten de Veluwezoom op last van de Duitsers evacueren. Beluister een fragment uit het dagboek van een 12-jarig meisje...',
      },
      // {
      //   id: 11703,
      //   type: ContentType.Video,
      //   thumbnail: 'assets/oosterbeek/images/dochter-kate-ter-horst.jpg',
      //   title: 'Dochter van Kate',
      //   text: 'Op 17 april 1945 keren Britse eenheden terug in Oosterbeek. Het verlaten dorp is dan nagenoeg verwoest, maar de bevrijding is een feit. Sophie is de dochter van Kate ter Horst en heeft de oorlog als kind meegemaakt.',
      //   url: 'https://mb.hetnieuwekader.nl/media/videos/00/c974bd48d05fc646f990ffd8bada2406.mp4', // TODO Moet nog aangeleverd worden
      //   videoThumbnail: 'assets/oosterbeek/images/dochter-kate-ter-horst.jpg',
      // },
      {
        id: 11704,
        type: ContentType.Puzzle,
        thumbnail: 'assets/oosterbeek/images/Ontsnappingslint_1024.jpg',
        url: 'assets/oosterbeek/images/Ontsnappingslint_1024.jpg',
        title: 'Ontsnappingslint',
        text: 'Gebruikersinstructie: de Britse soldaten ontsnapten met hulp van een wit lint, gemaakt van hun parachutes. In het donker lopen ze naar de rivier en gebruiken het witte lint ter oriëntatie. Weet jij het lint te ontwarren?',
      },

      //---------------------------------------------------------------------------------------------------------

      {
        id: 12201,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/algemeen/images/candleholder.png',
        completionText: 'Je hebt alle kaarsen opgestoken ter nagedachtenis van de gevallenen.',
        targets: [
          { id: 0, x: 12, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 1, x: 31, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 2, x: 48, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 3, x: 67, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' }
        ],
        title: 'Virtuele kaars',
        text: 'Steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 12202,
        type: ContentType.Share,
        thumbnail: '',
        text: 'Maak een selfie en deel die met je vrienden en familie.',
        title: 'Foto maken',
      },

    ]
  },

  // NIJMEGEN -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 2,
    name: 'Nijmegen',
    cover: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 1.jpg',
    category: 1,
    city: 'Nijmegen',
    street: 'Ridderstraat 27',
    zip: '6511 TM',
    length: 1500,
    position: {
      lat: 51.847257,
      lng: 5.868295,
    },
    description: 'Tijdens de Slag om Nijmegen in september 1944 wordt er hard gevochten hier op en rondom het Valkhof. In deze wandeling komen de spannende gebeurtenissen weer tot leven.',
    content: [

      // Nijmegen - Locatie 1

      {
        id: 201,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/nijmegen/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar september 1944.',
      },
      {
        id: 202,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 4.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 4.jpg',
            title: '',
            text: 'Voormalig politiebureau. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 1.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 1.jpg',
            title: '',
            text: 'Voorbereiding 82nd Airborne Division 17-09-1944. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 2.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 2.jpg',
            title: "",
            text: "Parachutisten stappen C47's in 17-09-1944. Bron: Fotocollectie Regionaal Archief Nijmegen",
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 3.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 3.jpg',
            title: '',
            text: 'Interieur C47-vliegtuig kort voor Market Garden 17-09-1944',
          },
        ],
        title: 'Infocentrum WO2 Nijmegen',
        text: 'Je staat trouwens al op een historische plek! Hier stond tijdens de oorlog een politiebureau. Nu is het Infocentrum WO2 Nijmegen hier gevestigd. Tip: Wil je na je wandeling meer weten over de Tweede Wereldoorlog dan is dit een aanrader.',
      },
      {
        id: 203,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Hoofdrolspelers',
        url: 'assets/nijmegen/videos/Video 2 - Combined_HB.mp4',
        videoThumbnail: '',
        text: 'Maak kennis met de hoofdrolspelers.',
      },
      {
        id: 204,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.848149, lng: 5.867695 },
        range: 15,
        text: 'Ga naar de Antoniusplaats via de tweede steeg. Gebruik je kompas.',
      },

      // Nijmegen - Locatie 2

      {
        id: 205,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 8.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 8.jpg',
            title: "Huize Bethlehem",
            text: 'Zusters bij Huize Bethlehem. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 9.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 9.jpg',
            title: 'Huize Bethlehem',
            text: 'Recreatieruimte Huize Bethlehem. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          /*
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 7.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 7.jpg',
            title: 'Huize Bethlehem',
            text: 'Karl-Heinz Euling',
          },
          */
        ],
        title: 'Huize Bethlehem',
        text: 'Dit is Huize Bethlehem, vlakbij de Waal. Vanaf 1927 verbleven de zusters Dominicanessen in dit pand en zuster Josepha zat hier tijdens de Slag om Nijmegen. Bron: Fotocollectie Regionaal Archief Nijmegen',
      },
      {
        id: 206,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Huize Bethlehem',
        question: 'Hoe weten we wat zuster Josepha ervaarde bij de Slag om Nijmegen?',
        correctIndex: 2,
        options: [
          'Via haar familie',
          'Geschiedenisboeken',
          'Haar dagboek',
        ],
      },
      {
        id: 207,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Dagboek',
        url: 'assets/nijmegen/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: 'Wat zagen zij op die 17e september 1944?',
      },
      {
        id: 208,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Start operatie',
        url: 'assets/nijmegen/videos/Video 4_HB.mp4',
        videoThumbnail: '',
        text: 'In 1978 schrijft James Gavin zijn herinneringen. Hoe herinnert hij zich de start van deze operatie?',
      },
      {
        id: 209,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.848083, lng: 5.870210 },
        range: 15,
        text: 'Loop terug langs het infocentrum WOII en ga links het Valkhofpark in naar de Valkhofbunker',
      },

      // Nijmegen - Locatie 3

      {
        id: 210,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Valkhofbunker',
        text: 'Dit is de ingang van de Valkhofbunker gelegen in het Valkhofpark. Dit park ligt hoog met goed zicht op de Waalbrug. Al vele eeuwen een strategische plek en dat vonden de Duitsers ook! Tijdens de oorlog hebben ze verschillende versterkingen aangelegd, waaronder drie betonnen bunkers. De aanleg hiervan begon in de zomer van 1943. Het Valkhofpark werd toen gesloten voor publiek en was verboden terrein. Na de oorlog zijn twee van de drie bunkers gesloopt – een al in 1947 en een in 1984. Deze bunker, waar je nu staat, is overeind gebleven en toegankelijk voor publiek. Je kunt een tentoonstelling bezoeken waar je kunt lezen, horen en ervaren wat de Duitse bezetter, de geallieerden en verzetslieden meemaakten in het Valkhofpark tijdens de bevrijding van Nijmegen.',
      },
      {
        id: 211,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 19.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 19.jpg',
            title: 'Valkhofbunker',
            text: 'Valkhofbunker gezien vanaf Waalkade. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 20.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 20.jpg',
            title: 'Valkhofbunker',
            text: 'Detailtekening van de Valkhofbunker. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 21.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 21.jpg',
            title: 'Valkhofbunker',
            text: 'Uitzicht vanuit een van de schietgaten Valkhofbunker. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 24.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 24.jpg',
            title: 'Valkhofbunker',
            text: 'Interieur Valkhofbunker. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 25.jpg',
            thumbnail: 'assets/nijmegen/images/Oorlogspad_route Nijmegen_afbeelding 25.jpg',
            title: 'Valkhofbunker',
            text: 'Interieur Valkhofbunker. Bron: Fotocollectie Regionaal Archief Nijmegen',
          },
        ],
        title: 'Valkhofbunker',
        text: 'De Valkhofbunker was een ideale plaats om je te verschuilen tijdens de strijd. Bron: Fotocollectie Regionaal Archief Nijmegen',
      },
      {
        id: 212,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Strijd rond het Valkhof',
        text: 'Bekijk de video.',
        url: 'assets/nijmegen/videos/Video 5_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 213,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Brand',
        text: 'Beluister het dagboekfragment.',
        url: 'assets/nijmegen/videos/Zuster Over De Brand 02_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 214,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Dit object hoort bij de volgende locatie.',
        model: 'assets/nijmegen/models/granaat_01_v2.glb',
        poster: 'assets/nijmegen/images/tankkogel-gevonden-1024.png',
      },
      {
        id: 215,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.847727, lng: 5.872445 },
        range: 15,
        text: 'Neem het pad naar beneden en steek rechts de weg over richting het kanon.',
      },

      // Nijmegen - Locatie 4

      {
        id: 216,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Kanon bij Belvédère',
        text: 'Dit is een Panzerabwehrkanone oftewel een antitankkanon. Het kanon is door het Duitse leger na de strijd om de Waalbrug in september 1944 achtergelaten en werd in 1947 als een oorlogstrofee hier onder de Belvedère toren geplaatst. De kogels die dit kanon kon afvuren hadden een diameter van vijf centimeter en konden dus veel schade aanrichten.',
      },
      {
        id: 217,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Kanon bij Belvédère (Hunnerpark)',
        question: 'De loop staat, zoals je kan zien, gericht op de Waalbrug. Dit is opmerkelijk. Waarom? ',
        correctIndex: 2,
        options: [
          'Met dit kanon wilden de Duitsers niet de Waalbrug, maar de spoorbrug aanvallen.',
          'Het is een antitankkanon, maar over de Waalbrug reden geen tanks.',
          'Het Duitse leger probeerde met dit kanon de Waalbrug juist te verdedigen tegen de aanvallen van de geallieerden.',
        ],
      },
      {
        id: 218,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Hunnerpark',
        text: 'Dit park was de oprit naar de Waalbrug.',
        url: 'assets/nijmegen/videos/Video 6_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 219,
        type: ContentType.Video,
        thumbnail: '',
        title: 'De Waaloversteek',
        text: 'Beluister Gavin’s herinneringen over de Waaloversteek (nagespeeld)',
        url: 'assets/nijmegen/videos/Gavin Oversteek En Vrouw Commentaar 02_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 220,
        type: ContentType.Video,
        thumbnail: '',
        title: 'De Waalbrug',
        text: 'Bekijk de video over de verovering van de Waalbrug.',
        url: 'assets/nijmegen/videos/Video 7_HB.mp4',
        videoThumbnail: '',
      },

      // Nijmegen - Locatie checkpoint 4.5
      {
        id: 2212,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.845997, lng: 5.872960 },
        range: 15,
        text: 'Loop verder door het Hunnerpark voor je volgende instructie',
      },
      {
        id: 221,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.846628, lng: 5.870643 },
        range: 15,
        text: 'Gebruik je kompas en loop links langs het museum naar de volgende stop op dit Oorlogspad.',
      },

      // Nijmegen - Locatie 5

      {
        id: 222,
        type: ContentType.Video,
        thumbnail: '',
        title: 'De ontsnapping',
        text: 'Beluister het verhaal over Karl-Heinz.',
        url: 'assets/nijmegen/videos/ontsnapping-eulings-en-commentaar-480.mp4',
        videoThumbnail: '',
      },
      {
        id: 223,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Successen',
        text: 'Het doel van Market Garden was niet behaald, maar er waren ook belangrijke successen.',
        url: 'assets/nijmegen/videos/Video 8_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 2232,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.847257, lng: 5.868295 },
        range: 15,
        text: 'Gebruik je kompas en loop naar het eindpunt van dit Oorlogspad.',
      },
      // eind locatie
      {
        id: 224,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Kris van der Aar',
        text: 'Bekijk de videoboodschap van Kris van der Aar.',
        url: 'assets/nijmegen/videos/Nijmegen_Kris_480p_compressed.mp4',
        videoThumbnail: '',
      },
      {
        id: 225,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [22501, 22502],
        title: 'Keuzemenu',
        text: 'Maak een foto en/of steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 226,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Vergeet niet om even het Infocentrum WO2 Nijmegen te bezoeken! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Nijmegen | Multi Content ------------------------------------------------------------------------------------------

      {
        id: 22501,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/algemeen/images/candleholder.png',
        completionText: 'Je hebt alle kaarsen opgestoken ter nagedachtenis van de gevallenen.',
        targets: [
          { id: 0, x: 12, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 1, x: 31, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 2, x: 48, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 3, x: 67, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' }
        ],
        title: 'Virtuele kaars',
        text: 'Steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 22502,
        type: ContentType.Share,
        thumbnail: '',
        text: 'Maak een selfie en deel die met je vrienden en familie.',
        title: 'Foto maken',
      },

    ]
  },

  // TIEL -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 3,
    name: 'Tiel',
    cover: 'assets/tiel/images/Afbeelding 1.jpg',
    category: 2,
    city: 'Tiel',
    street: 'Havendijk (Waterpoort)',
    zip: '4001 KV',
    length: 1800,
    position: {
      lat: 51.886504,
      lng: 5.439846,
    },
    description: 'Op veel plekken in Nederland barst na de bevrijding op 5 mei 1945 een volksfeest los. De weinigen die een kijkje kunnen nemen in het verwoeste Tiel zijn echter allesbehalve blij! Maak een bijzondere wandeling door het naoorlogse Tiel.',
    content: [

      // Tiel - Locatie 1

      {
        id: 301,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/tiel/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar de tijd vlak na de Tweede Wereldoorlog.',
      },
      {
        id: 302,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.887163, lng: 5.438671 },
        range: 15,
        text: 'Loop in de richting van de Markt. Gebruik je kompas.',
      },

      // Tiel - Locatie 2

      {
        id: 303,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Nasleep',
        url: 'assets/tiel/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'In de nasleep van de hevige gevechten in de winter van 1944, wordt Tiel een levensgevaarlijke plek.',
      },
      {
        id: 304,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/tiel/images/afbeelding-2-toen-1024_16-9.jpg',
        outlineImage: 'assets/tiel/images/afbeelding-2-toen-1024_16-9.jpg',
        title: 'De Markt',
        text: 'Wil je weten hoe de Markt eruitzag in die tijd? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts.',
      },
      {
        id: 305,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.888262, lng: 5.439465 },
        range: 15,
        text: 'Loop in de richting van de watertoren. Gebruik je kompas.',
      },

      // Tiel - Locatie 3

      {
        id: 306,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Watertoren',
        url: 'assets/tiel/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: 'De watertoren herstellen was van levensbelang.',
      },
      {
        id: 307,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Reportage',
        url: 'assets/tiel/videos/over-watertoren-compressed-480.mp4',
        videoThumbnail: '',
        text: 'Bekijk de archiefreportage met Klaas, de zoon van de aannemer.',
      },
      {
        id: 308,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/tiel/images/Oorlogschade Waterstraat.jpg',
            thumbnail: 'assets/tiel/images/Oorlogschade Waterstraat.jpg',
            title: 'De Watertoren',
            text: 'Waterstraat',
          },
          {
            url: 'assets/tiel/images/Foto door GW Bouwhuis Tiel.jpg',
            thumbnail: 'assets/tiel/images/Foto door GW Bouwhuis Tiel.jpg',
            title: 'De Watertoren',
            text: 'Foto: G.W. Bouwhuis',
          },
          {
            url: 'assets/tiel/images/STRBAGNIETENSTR1945.jpg',
            thumbnail: 'assets/tiel/images/STRBAGNIETENSTR1945.jpg',
            title: 'De Watertoren',
            text: 'Agnietenstraat',
          },
          {
            url: 'assets/tiel/images/Vleestraat.jpg',
            thumbnail: 'assets/tiel/images/Vleestraat.jpg',
            title: 'De Watertoren',
            text: 'Vleestraat',
          },
        ],
        title: 'Tiel in puin',
        text: 'Bekijk de beelden van Tiel in puin.',
      },
      // Tiel - Locatie 3.5 checkpoint
      {
        id: 3092,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat:  51.889583, lng: 5.438383 },
        range: 15,
        text: 'Loop door naar de Hoveniersweg voor de volgende instructie. Gebruik je kompas.',
      },
      {
        id: 309,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.888780, lng: 5.435048 },
        range: 15,
        text: 'Loop richting de Konijnenwal 10. Gebruik je kompas.',
      },

      // Tiel - Locatie 4

      {
        id: 310,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Deze koe is een aandenken aan de evacuatie.',
        model: 'assets/tiel/models/koetje_v2.glb',
        poster: 'assets/tiel/images/koetje-gevonden-1024.png',
      },
      {
        id: 311,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Koeienbeeldje',
        text: 'Bekijk de video over de oorsprong van het beeldje.',
        url: 'assets/tiel/videos/Video 4_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 312,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.887492, lng: 5.434618 },
        range: 15,
        text: 'Loop richting de Gasthuisstraat 23-25. Gebruik je kompas.',
      },

      // Tiel - Locatie 5

      {
        id: 313,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Wederopbouw',
        text: "Beluister het verhaal over het 'Herbouwplan voor de binnenstad'.",
        url: 'assets/tiel/videos/Video 5_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 314,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Mijlpaal',
        text: 'Bekijk de video over de 50.000ste woning.',
        url: 'assets/tiel/videos/woningen-in-tiel-compressed-480.mp4',
        videoThumbnail: '',
      },
      {
        id: 315,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Wederopbouw',
        text: 'Bekijk de video.',
        url: 'assets/tiel/videos/Video 6_HB.mp4',
        videoThumbnail: '',
      },

      
      // Tiel - Locatie eind checkpoint
      {
        id: 3162,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.885306, lng: 5.437557 },
        range: 15,
        text: 'Voor de volgende instructie volg je de straat tot de Tolhuiswal. Gebruik je kompas.',
      },

      // Tiel - Locatie eind
      {
        id: 3163,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.886444, lng: 5.439605 },
        range: 15,
        text: 'Loop terug naar het begin van de route bij de Waterpoort. Gebruik je kompas.',
      },

      {
        id: 316,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Videoboodschap',
        text: 'Bekijk de video met Jacoline Zilverschoon',
        url: 'assets/tiel/videos/tiel-jacoline-zilverschoon-480.mp4',
        videoThumbnail: '',
      },
      {
        id: 317,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Meer weten?',
        text: 'Als je meer verhalen wil ontdekken over Tiel tijdens de Tweede Wereldoorlog, breng dan een bezoek aan het Flipje- en Streekmuseum. Ontdek persoonlijke en aangrijpende verhalen van de inwoners van Tiel.',
      },
      {
        id: 318,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

    ]
  },


  // APELDOORN -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 4,
    name: 'Apeldoornsche Bosch',
    cover: 'assets/apeldoorn/images/3 Hoofdgebouw_Apeldoornsche_Bosch_ca1930.jpg',
    category: 3,
    city: 'Apeldoorn',
    street: 'Zutphensestraat 76',
    zip: '7325 XJ',
    length: 2600,
    position: {
      lat: 52.210829,
      lng: 6.000566,
    },
    description: "De Duitse bezetter wil van het Apeldoornsche Bosch een ontspanningsoord voor SS'ers maken, met desastreuze gevolgen voor de Joodse psychiatrische patiënten en het personeel. Wandel over het terrein en ontdek wat hier gebeurde.\n\nDe route begint bij het Herinneringscentrum. Parkeren kan aan de overkant bij het skatepark, De Voorwaarts 7321 CM Apeldoorn.",
    content: [

      // Apeldoorn - Locatie 1

      {
        id: 401,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/apeldoorn/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar 1942.',
      },
      {
        id: 402,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [40201, 40202, 40203],
        title: 'Hoofdrolspelers',
        text: 'Maak kennis met de hoofdrolspelers.',
      },

      // Apeldoorn - Locatie 1.5 checkpoint
      
      {
        id: 4022,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.208244, lng: 6.004923 },
        range: 15,
        text: 'Loop via de Benjaminlaan naar je volgende instructie. Gebruik je kompas.',
      },

      // Apeldoorn - Locatie 2
      {
        id: 4023,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.209013, lng: 6.007266 },
        range: 15,
        text: 'Loop door naar het einde van de Lobsteinlaan. Aan de overkant zie je gebouw Hannah.',
      },
      {
        id: 4024,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/apeldoorn/images/personeel_hannah.jpg',
            thumbnail: 'assets/apeldoorn/images/personeel_hannah.jpg',
            title: '',
            text: 'Personeel van Paviljoen Hannah.',
          },
        ],
        title: 'Paviljoen Hannah',
        text: 'Paviljoen Hannah was een van de eerste gebouwen op het terrein en is nu rijksmonument. Het gebouw is een ontwerp van architect E.M. Rood en werd geopend op 17 januari 1935. Paviljoen Hannah, dat in eerste instantie paviljoen D heette, diende als eersteklas huisvesting voor 32 vrouwelijke patiënten. Het was ook een observatieafdeling voor nieuwe patiënten. ',
      },

      {
        id: 403,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.208645, lng: 6.009192 },
        range: 15,
        text: 'Gebruik het kompas en ga naar de voormalige plek van het hoofdgebouw.',
      },

      // Apeldoorn - Locatie 3

      {
        id: 404,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/apeldoorn/images/apeldoornscheboschprentbrief-1024.jpg',
            thumbnail: 'assets/apeldoorn/images/apeldoornscheboschprentbrief-1024.jpg',
            title: 'Het hoofdgebouw',
            text: 'Zo zag het hoofdgebouw eruit.',
          },
          {
            url: 'assets/apeldoorn/images/1951-0434 plattegrond terrein_k.jpg',
            thumbnail: 'assets/apeldoorn/images/1951-0434 plattegrond terrein_k.jpg',
            title: 'Plattegrond',
            text: 'Plattegrond van het gehele terrein.',
          },
        ],
        title: 'Het hoofdgebouw',
        text: 'Zo zag het hoofdgebouw eruit. Wil je weten hoe het leven hier was? Ga dan snel verder.',
      },
      {
        id: 405,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Voor de oorlog',
        url: 'assets/apeldoorn/videos/Archieffilm Apeldoornsche Bosch-compressed.mp4',
        videoThumbnail: '',
        text: 'Bekijk fragmenten uit een archieffilm van voor de oorlog.',
      },
      {
        id: 406,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/apeldoorn/images/De eetkamer.jpg',
            thumbnail: 'assets/apeldoorn/images/De eetkamer.jpg',
            title: 'Paedagogium Achisomog',
            text: 'De eetkamer',
          },
          {
            url: 'assets/apeldoorn/images/De oudere meisjes helpen in het huishouden.jpg',
            thumbnail: 'assets/apeldoorn/images/De oudere meisjes helpen in het huishouden.jpg',
            title: 'Paedagogium Achisomog',
            text: 'De oudere meisjes helpen in het huishouden',
          },
          {
            url: 'assets/apeldoorn/images/De school.jpg',
            thumbnail: 'assets/apeldoorn/images/De school.jpg',
            title: 'Paedagogium Achisomog',
            text: 'De school',
          },
          {
            url: 'assets/apeldoorn/images/De was.jpg',
            thumbnail: 'assets/apeldoorn/images/De was.jpg',
            title: 'Paedagogium Achisomog',
            text: 'De was',
          },
          {
            url: 'assets/apeldoorn/images/Huisvlijt voor de jongens.jpg',
            thumbnail: 'assets/apeldoorn/images/Huisvlijt voor de jongens.jpg',
            title: 'Paedagogium Achisomog',
            text: 'Huisvlijt voor de jongens',
          },
          {
            url: 'assets/apeldoorn/images/Slaapkamers.jpg',
            thumbnail: 'assets/apeldoorn/images/Slaapkamers.jpg',
            title: 'Paedagogium Achisomog',
            text: 'Slaapkamers',
          },
        ],
        title: 'Paedagogium Achisomog',
        text: '‘Opvoedingsgesticht voor Joodsche geesteszwakke kinderen te Apeldoorn’ Bron foto’s: Archief Amsterdam.',
      },
      {
        id: 407,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Het noodlot',
        url: 'assets/apeldoorn/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video.',
      },
      {
        id: 408,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.206948, lng: 6.012776 },
        range: 15,
        text: 'Volg deze laan de bocht om naar Paviljoen G, de werkplek van de jonge verpleegster Claartje.',
      },

      // Apeldoorn - Locatie 4

      {
        id: 409,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Ontruiming',
        url: 'assets/apeldoorn/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: 'Er was nog één laatste kans.',
      },
      {
        id: 410,
        type: ContentType.Video,
        thumbnail: '',
        url: 'assets/apeldoorn/videos/Backdrop + Claartje Audio_HB.mp4',
        title: 'Afscheidsbrief',
        text: 'De jonge verpleegster Claartje van Aals blijft en schrijft een afscheidsbrief aan haar vriendin Aagje.',
      },
      {
        id: 411,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.207133, lng: 6.007931 },
        range: 15,
        text: 'Loop door en ga rechtsaf bij het Vierhuis. Gebruik je kompas of de kaart.',
      },

      // Apeldoorn - Locatie 5

      {
        id: 412,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/apeldoorn/images/Matching foto ontspanningsgebouw toen_16-9.jpg',
        outlineImage: 'assets/apeldoorn/images/Matching foto ontspanningsgebouw toen_16-9.jpg',
        title: 'Toen en nu',
        text: "Wil je weten hoe het Ontspanningsgebouw er uitzag in die tijd? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts.",
      },
      {
        id: 413,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Deportatie',
        text: 'Samir vertelt over de gebeurtenissen.',
        url: 'assets/apeldoorn/videos/Video 4_HB.mp4',
        videoThumbnail: '',
      },
      {
        id: 414,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Dit is een collectebusje van het Apeldoornsche Bosch, een van de weinige voorwerpen die zijn overgebleven.',
        model: 'assets/apeldoorn/models/Collectebusje_01.glb',
        poster: 'assets/apeldoorn/images/collectebusje_01_thumbnail.png',
      },
       // eind checkpoint
       {
        id: 4143,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.207642, lng: 6.004428 },
        range: 15,
        text: 'Volg de Hannalaan naar links tot de volgende instructie. Gebruik je kompas of de kaart.',
      },
      // eindlocatie
      {
        id: 4142,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 52.210829, lng: 6.000566 },
        range: 15,
        text: 'Ga terug naar het Herinneringscentrum. Gebruik je kompas of de kaart.',
      },
      {
        id: 415,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Videoboodschap',
        text: 'Theo de Korte is verbonden aan het Herinneringscentrum Apeldoornsche Bosch. Hij vertelt waarom hij het verleden niet los wil laten...',
        url: 'assets/apeldoorn/videos/Testimonial Theo De Korte-compressed.mp4',
        videoThumbnail: '',
      },
      {
        id: 416,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/algemeen/images/candleholder.png',
        completionText: 'Je hebt alle kaarsen opgestoken ter nagedachtenis van de gevallenen.',
        targets: [
          { id: 0, x: 12, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 1, x: 31, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 2, x: 48, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 3, x: 67, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' }
        ],
        title: 'Virtuele kaars',
        text: 'Steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 417,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Apeldoorn | Multi Content ------------------------------------------------------------------------------------------

      {
        id: 40201,
        type: ContentType.Text,
        thumbnail: 'assets/apeldoorn/images/lobstein-1024.jpg',
        title: 'Dr. Jacques Lobstein',
        text: 'Hij is de geneesheer-directeur van het Apeldoornsche Bosch. Lobstein is een bescheiden, sociaal en hardwerkend man die veel geïnvesteerd heeft in wetenschappelijk onderzoek. Lobstein wordt gedeporteerd en komt op 15 februari 1944 in concentratiekamp Bergen Belsen aan. In april 1945 wordt hij op de trein gezet richting Theresienstadt, maar die trein komt daar nooit aan. In Tröbitz bevrijd, maar zeer ziek komt hij te overlijden.',
      },
      {
        id: 40202,
        type: ContentType.Text,
        thumbnail: 'assets/apeldoorn/images/ferdinand-1024.jpg',
        title: 'SS-Hauptsturmführer Ferdinand Aus der Fünten',
        text: "Ferdinand Hugo aus der Fünten geeft leiding aan de deportatie van tienduizenden Joden in Amsterdam en ook in Apeldoorn. Ook is hij betrokken bij talrijke razzia's. Na de oorlog wordt hij als oorlogsmisdadiger ter dood veroordeeld. Die straf wordt uiteindelijk omgezet in levenslang. Hij belandt in de koepelgevangenis in Breda en wordt onder veel protest in 1989 vrijgelaten.",
      },
      {
        id: 40203,
        type: ContentType.Text,
        thumbnail: 'assets/apeldoorn/images/claartje-1024.jpg',
        title: 'Claartje van Aals',
        text: 'Claartje is als jonge Joodse verpleegster werkzaam in de Joods psychiatrische kliniek. Wanneer de patiënten op transport worden gezet, blijft zij bij hen. Ze wordt op 20-jarige leeftijd in Auschwitz om het leven gebracht.',
      },

    ]
  },

  // WAGENINGEN -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 5,
    name: 'Wageningen',
    cover: 'assets/wageningen/images/hotel-de-wereld-toen.jpg',
    category: 4,
    city: 'Wageningen',
    street: 'Bowlespark 1A',
    zip: '6701 DN',
    length: 1800,
    position: {
      lat: 51.964689,
      lng: 5.665740,
    },
    description: "Waarom is uitgerekend Wageningen ‘de Stad der Bevrijding’ geworden? Ontdek het in deze wandeling door de binnenstad.",
    content: [

      // Wageningen - Locatie 1

      {
        id: 501,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/wageningen/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar 2 mei 1945.',
      },
      {
        id: 502,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Afbeelding voedselconferentie Achterveld.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Afbeelding voedselconferentie Achterveld.jpg',
            title: 'Verwoesting Wageningen',
            text: 'Voedselconferentie Achterveld',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Honger-in-Den-Haag-bron-Geheugen-van-Nederland.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Honger-in-Den-Haag-bron-Geheugen-van-Nederland.jpg',
            title: 'Voedseltransporten',
            text: 'Honger in Den Haag. Bron: Geheugen van Nederland',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Illustratie bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Illustratie bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Illustratie. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust Duitse soldaten Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust Duitse soldaten Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust - Duitse soldaten. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust overleg Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust overleg Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust - Overleg. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust persmoment Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust persmoment Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust - Persmoment. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust voedsel laden Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust voedsel laden Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust - Voedsel laden. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust voedseltransport Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 1/Operatie Faust voedseltransport Bron Casteelse Poort.jpg',
            title: 'Voedseltransporten',
            text: 'Operatie Faust - Voedseltransport. Bron: Casteelse Poort',
          },
        ],
        title: 'Voedseltransporten',
        text: 'Bekijk de afbeeldingen van de voedseltransporten.',
      },
      {
        id: 503,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Voedseltransporten en capitulatie',
        text: "Twee gebeurtenissen uit de laatste oorlogsjaren zijn dus onlosmakelijk verbonden met Wageningen; de voedseltransporten en de capitulatie, de overgave van de Duitse bezetter. Omdat in het westen van Nederland al maanden gebrek is aan eten, besluiten de geallieerden om met de Duitsers te onderhandelen over het sturen van voedselhulp. Op 30 april sluiten ze het Akkoord van Achterveld in de buurt van Amersfoort. Er is dan al een paar keer in het geheim onderhandeld. \n\nGa verder om de video te bekijken.",
      },
      {
        id: 504,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Voedseltransporten',
        url: 'assets/wageningen/videos/Wageningen Voedseltransporten Level_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk archiefbeeld over de voedseltransporten.',
      },
      // Wageningen locatie 1.5 checkpoint
      {
        id: 5052,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.96358, lng: 5.66294},
        range: 15,
        text: 'Loop via de Niemeijerstraat richting de gracht voor de volgende instructie.',
      },
      {
        id: 505,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.964907, lng: 5.661812 },
        range: 15,
        text: 'Loop naar het begin van de Hoogstraat - ca. 300 m naar de kerk op de Markt. Gebruik je kompas.',
      },

      // Wageningen - Locatie 2

      {
        id: 506,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Grote Kerk',
        url: 'assets/wageningen/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de Grote Kerk.',
      },
      {
        id: 507,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Duitse soldaten in binnenstad_1.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Duitse soldaten in binnenstad_1.jpg',
            title: 'Verwoestingen',
            text: 'Duitse soldaten in binnenstad',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Duitse soldaten in binnenstad_2.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Duitse soldaten in binnenstad_2.jpg',
            title: 'Verwoestingen',
            text: 'Duitse soldaten in binnenstad',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_1.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_1.jpg',
            title: 'Verwoestingen',
            text: 'Evacuatie',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_2.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_2.jpg',
            title: 'Verwoestingen',
            text: 'Evacuatie',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_3.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_3.jpg',
            title: 'Verwoestingen',
            text: 'Evacuatie',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_4.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_4.jpg',
            title: 'Verwoestingen',
            text: 'Evacuatie',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_schilderij Bron Casteelse Poort.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatie_schilderij Bron Casteelse Poort.jpg',
            title: 'Verwoestingen',
            text: 'Schilderij van de evacuatie. Bron: Casteelse Poort',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Evacuatiebevel.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Evacuatiebevel.jpg',
            title: 'Verwoestingen',
            text: 'Evacuatiebevel',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Huizen weggevaagd.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Huizen weggevaagd.jpg',
            title: 'Verwoestingen',
            text: 'Huizen weggevaagd',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Kaart evacuaties.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Kaart evacuaties.jpg',
            title: 'Verwoestingen',
            text: 'Kaart evacuaties',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Kaart huizen 1940.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Kaart huizen 1940.jpg',
            title: 'Verwoestingen',
            text: 'Kaart huizen 1940',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Kerk oorlogsschade.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Kerk oorlogsschade.jpg',
            title: 'Verwoestingen',
            text: 'Kerk oorlogsschade',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Met de grond gelijk.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Met de grond gelijk.jpg',
            title: 'Verwoestingen',
            text: 'Met de grond gelijk',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Oorlogsschade binnenstad.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Oorlogsschade binnenstad.jpg',
            title: 'Verwoestingen',
            text: 'Oorlogsschade binnenstad',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Pand aan de Beekstraat.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Pand aan de Beekstraat.jpg',
            title: 'Verwoestingen',
            text: 'Pand aan de Beekstraat',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Puinhopen.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Puinhopen.jpg',
            title: 'Verwoestingen',
            text: 'Puinhopen',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Stad in puin.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Stad in puin.jpg',
            title: 'Verwoestingen',
            text: 'Stad in puin',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Stijl Deltse school.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Stijl Deltse school.jpg',
            title: 'Verwoestingen',
            text: 'Stijl Delftse school',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Verwoeste binnenstad.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Verwoeste binnenstad.jpg',
            title: 'Verwoestingen',
            text: 'Verwoeste binnenstad',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Verwoesting kerk.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Verwoesting kerk.jpg',
            title: 'Verwoestingen',
            text: 'Verwoesting kerk',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Verwoesting rond de kerk.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Verwoesting rond de kerk.jpg',
            title: 'Verwoestingen',
            text: 'Verwoesting rond de kerk',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Verwoestingen Wageningen_1.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Verwoestingen Wageningen_1.jpg',
            title: 'Verwoestingen',
            text: 'Verwoestingen Wageningen',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Wageningen in oorlogstijd.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Wageningen in oorlogstijd.jpg',
            title: 'Verwoestingen',
            text: 'Wageningen in oorlogstijd',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw stijl Delftse School.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw stijl Delftse School.jpg',
            title: 'Verwoestingen',
            text: 'Wederopbouw stijl Delftse School',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw_1.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw_1.jpg',
            title: 'Verwoestingen',
            text: 'Wederopbouw',
          },
          {
            url: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw.jpg',
            thumbnail: 'assets/wageningen/images/Fotogalerij 2/Wederopbouw.jpg',
            title: 'Verwoestingen',
            text: 'Wederopbouw',
          },
        ],
        title: 'Verwoestingen',
        text: 'Bekijk beelden van de verwoestingen in Wageningen.',
      },
      {
        id: 508,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Evacuaties',
        text: "De bewoners van Wageningen moeten tot twee keer toe evacueren. Omdat Wageningen dichtbij de Grebbelinie ligt, wordt uit voorzorg de gehele bevolking volgens plan geëvacueerd op 10 mei 1940. Na de luchtlandingen van de geallieerden op 17 september 1944 volgt een tweede evacuatie. Dit keer afgedwongen door de Duitsers. Die vinden het te gevaarlijk met een vijandige bevolking in de buurt. Iedereen moet vóór 1 oktober 1944 Wageningen verlaten. Die evacuatie duurt maar liefst acht maanden.",
      },
      {
        id: 509,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.964817, lng: 5.663387 },
        range: 15,
        text: 'Loop naar de achterzijde van de kerk en ontdek het herdenkingsraam. Gebruik je kompas of de kaart.',
      },

      // Wageningen - Locatie 3

      {
        id: 510,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Herdenkingsraam',
        text: "Hier aan de achterkant van deze kerk bevindt zich een monument in glas: het Herdenkingsraam Bevrijding 1940-1945. Het raam staat bol van symboliek. Van de buitenkant kan je dat niet zien. Daarom hebben wij hem voor je in het licht gezet. Wel even puzzelen om het raam kloppend te krijgen. Ben benieuwd wat jij allemaal ontdekt!",
      },
      {
        id: 511,
        type: ContentType.Puzzle,
        thumbnail: 'assets/wageningen/images/Schuifpuzzel_bevrijdingsraam.jpg',
        url: 'assets/wageningen/images/Schuifpuzzel_bevrijdingsraam.jpg',
        title: 'Herdenkingsraam',
        text: 'Los de puzzel op!',
      },

      // Wageningen - Locatie 3.5 checkpoint
      {
        id: 5122,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.96600, lng: 5.66315 },
        range: 15,
        text: 'Voor je volgende instructie loop door via de Vijzelstraat in noordelijke richting naar de Hoogstraat ',
      },
      {
        id: 512,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.967429, lng: 5.667568 },
        range: 15,
        text: 'Loop in de richting van Hotel de Wereld. Gebruik je kompas.',
      },

      // Wageningen - Locatie 4

      {
        id: 513,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Het is namelijk de hoogste tijd om je voor te stellen aan degenen die hem hebben gebruikt bij de ondertekening van de uitvoeringsbevelen voor de overgave. ',
        model: 'assets/wageningen/models/VredesPen_02_larger.glb',
        poster: 'assets/wageningen/images/pen-thumbnail.png',
      },
      {
        id: 514,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [51401, 51402, 51403],
        title: 'Hoofdrolspelers',
        text: 'Maak kennis met de hoofdrolspelers.',
      },
      {
        id: 515,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/wageningen/images/hotel-de-wereld-toen.jpg',
        outlineImage: 'assets/wageningen/images/hotel-de-wereld-toen.jpg',
        title: 'Toen en nu',
        text: "Wil je weten hoe hotel De Wereld eruitzag in mei 1945? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts.",
      },
      {
        id: 516,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Capitulatie',
        url: 'assets/wageningen/videos/Video 3 + 4 Combined_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de capitulatie.',
      },
      {
        id: 517,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Videoboodschap',
        url: 'assets/wageningen/videos/Wageningen Hans Hooft Level_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de videoboodschap van Hans Hooft, eigenaar van Hotel de Wereld.',
      },

      // Wageningen - Locatie 4.5 checkpoint
      {
        id: 5182,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.96588, lng: 5.6670661 },
        range: 15,
        text: 'Loop naar de voetgangersbrug over de Stadsgracht voor je volgende instructie.',
      },
      {
        id: 518,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.964903, lng: 5.665876 },
        range: 15,
        text: 'Loop via het park naar Museum De Casteelse Poort. Gebruik je kompas.',
      },
      // Locatie 5

      {
        id: 519,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Museum de Casteelse Poort',
        text: "Als je meer wilt weten over de bevrijding van Nederland in 1945 dan loop je even binnen in museum De Casteelse Poort. Hier is een zaal speciaal gewijd aan de periode 1940-1945. Is het museum gesloten? Geen nood. Voor de deur ligt een luisterkei van de Liberation Route.",
      },
      {
        id: 520,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [52001, 52002],
        title: 'Keuzemenu',
        text: 'Maak een foto en deel die met je vrienden of familie, of ontsteek het bevrijdingsvuur.',
      },
      {
        id: 521,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Wageningen | Multi Content ------------------------------------------------------------------------------------------

      {
        id: 51401,
        type: ContentType.Text,
        thumbnail: 'assets/wageningen/images/Foulkes.jpg',
        title: 'Lt.-Gen. Charles Foulkes',
        text: 'De Canadese generaal Charles Foulkes voert gesprekken over de uitvoeringsbevelen voor de Duitse troepen in Nederland.',
      },
      {
        id: 51402,
        type: ContentType.Text,
        thumbnail: 'assets/wageningen/images/Blaskowitz.jpg',
        title: 'Duitse generaal Johannes Blaskowitz',
        text: "Johannes Blaskowitz zegt ‘jawohl’ tegen de bevelen tot overgave van de Duitse troepen in Nederland. Hij pleegt op 5 februari 1948 zelfmoord in de gevangenis in Neurenberg.",
      },
      {
        id: 51403,
        type: ContentType.Text,
        thumbnail: 'assets/wageningen/images/Bernard.jpg',
        title: 'Z.K.H. Prins Bernhard',
        text: 'Geen militair kopstuk in dit rijtje maar wel de vertegenwoordiger van ons land als bevelhebber van de Nederlandse Binnenlandse Strijdkrachten (NBS).',
      },
      {
        id: 52001,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/wageningen/images/bevrijdingsvuur-bg.png',
        completionText: 'Goed gedaan! Je hebt het bevrijdingsvuur aangestoken. En besef dat vrijheid niet vanzelfsprekend is.',
        targets: [
          { id: 0, x: 0, y: 0, size: 100, image: 'assets/wageningen/images/bevrijdingsvuur-fg.png' },
        ],
        title: 'Bevrijdingsvuur',
        text: 'Ontsteek het bevrijdingsvuur door er op te tikken.',
      },
      {
        id: 52002,
        type: ContentType.Share,
        thumbnail: '',
        text: 'Maak een foto en deel die met je vrienden of familie.',
        title: 'Foto maken',
      },

    ]
  },

  // AALTEN -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 6,
    name: 'Aalten',
    cover: 'assets/aalten/images/1945 - DNB F00125.jpg',
    category: 5,
    city: 'Aalten',
    street: 'Frerikspad (Markt)',
    zip: '7121 CS',
    length: 1100,
    position: {
      lat: 51.927788,
      lng: 6.58145,
    },
    description: 'In heel de Achterhoek en ook in Aalten wordt aan veel onderduikers onderdak gegeven. In deze wandeling maak je kennis met moedige verzetshelden die vele levens redden. ',
    content: [

      // START

      {
        id: 601,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.927543, lng: 6.581471 },
        range: 15,
        text: 'Gebruik je kompas: ga via het Frerikspad naar de Markt en sla linksaf.',
      },
      // Aalten - Locatie 1
      {
        id: 602,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/aalten/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'We beginnen in het kloppend hart van Aalten.',
      },
      {
        id: 603,
        type: ContentType.Panorama,
        thumbnail: '',
        url: 'assets/aalten/images/panorama_aalten_01.jpg',
        title: 'Terug in de tijd',
        text: 'Ervaar hoe het toen was in 360 graden',  // TODO info icons nog niet op de goede plek
        infoSpots: [
          { x: 2000, y: 0, z: -1800, image: 'assets/aalten/images/Panorama hotspots/1.jpg', message: "<b>1939 - MOBILISATIE</b> </br> We werden als soldaten opgeroepen voor de strijd. Hier op deze plek hebben we afscheid genomen." },
          { x: -1900, y: -200, z: 2000, image: 'assets/aalten/images/Panorama hotspots/2.jpg', message: "<b>1940 - OPVANG</b> </br> Ik kwam uit Rotterdam na het bombardement op de stad. Alles lag daar in puin. We werden in Aalten met een heleboel kinderen verzameld en opgevangen. Konden we een beetje op verhaal komen na die gruwelijke gebeurtenis." },
          { x: -2500, y: 200, z: -2000, image: 'assets/aalten/images/Panorama hotspots/3.jpg', message: "<b>1943 - EVACUEES</b> </br> Hier hebben we gestaan, 500 evacués uit Scheveningen. We werden hartverwarmend begroet en kregen onderdak. Onze eigen huizen werden gesloopt voor de bouw van de Atlantik Wall. Dat was een verdedigingslinie van de Duitse bezetter langs de kust, ook waar wij woonden." },
          { x: 50, y: -300, z: 3500, image: 'assets/aalten/images/Panorama hotspots/4.jpg', message: '<b>1943 - ONDERDUIKEN EN VERZET</b> </br> Vanuit de dokterspraktijk van dokter Der Weduwen werd hier, vlakbij de Markt, een Joods kindje te vondeling gelegd, bij het huis van verzetsleider Jan Wikkerink. Wil je meer weten over onderduiken en verzet? </br> <a href="https://nationaalonderduikmuseum.nl/" target="_blank"><b>Klik hier</b></a>' },
          { x: -100, y: 0, z: -2500, image: 'assets/aalten/images/Panorama hotspots/5.jpg', message: "<b>1945 - DE BEVRIJDING</b> </br> Wat was het een feest op 30 maart 1945! We waren bevrijd en ik stond te dansen bovenop een Britse tank! Jennie Kempink woonde als kind op de Markt nummer 12. Pas na de oorlog hoorde ze wat zich had afgespeeld in haar ouderlijk huis. In dit huis is nu het Nationaal Onderduikmuseum gevestigd." },
          { x: 2500, y: 0, z: 650, image: 'assets/aalten/images/Panorama hotspots/6.jpg', message: "<b>1945 - BURGERS AAN HET FRONT</b> </br> 450 mannen van het Dutch National Battalion werden hier op deze markt opgesteld en uitgewuifd. Ik was erbij. We hielpen het Canadese leger aan het front om de rest van Nederland te bevrijden." },
        ]
      },
      {
        id: 604,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/Fotogalerij 1/1939-1940 - F00002.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/1939-1940 - F00002.jpg',
            title: '1939-1940',
            text: 'Mobilisatie strijd en terugkeer en komst Duitse soldaten | © Nationaal Onderduikmuseum',
          },
          {
            url: 'assets/aalten/images/Fotogalerij 1/1943 - F00040.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/1943 - F00040.jpg',
            title: '1943',
            text: 'Oorlog en evacuees | © Nationaal Onderduikmuseum',
          },
          {
            url: 'assets/aalten/images/Fotogalerij 1/wimpke-herfstink.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/wimpke-herfstink.jpg',
            title: '1943',
            text: 'Wimpke Herfstink - Aaron Jan Willem Jedwab bij familie Wikkerink | © Nationaal Onderduikmuseum',
          },
          {
            url: 'assets/aalten/images/Fotogalerij 1/1945 - DNB Dutch National Battalion.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/1945 - DNB Dutch National Battalion.jpg',
            title: '1945',
            text: 'Dutch National Bataljon | © Nationaal Onderduikmuseum',
          },
          {
            url: 'assets/aalten/images/Fotogalerij 1/1945 - F00011.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/1945 - F00011.jpg',
            title: '1945',
            text: 'De bevrijding door het Britse leger | © Nationaal Onderduikmuseum',
          },
          {
            url: 'assets/aalten/images/Fotogalerij 1/Vlucht Duitse soldaten - F00095.jpg',
            thumbnail: 'assets/aalten/images/Fotogalerij 1/Vlucht Duitse soldaten - F00095.jpg',
            title: '',
            text: 'Vlucht Duitse soldaten | © Nationaal Onderduikmuseum',
          },
        ],
        title: 'Oorlog in Aalten',
        text: 'Bekijk de beelden van Aalten tijdens de Tweede Wereldoorlog.',
      },
      {
        id: 609,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Aron Jedwab',
        url: 'assets/aalten/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'Samir neemt je mee terug naar 1943.',
      },
      {
        id: 610,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/aalten/audio/Wimke weghalen.mp3',
        title: 'Nieuw onderkomen',
        text: 'Beluister het geluidsfragment.',
      },
      {
        id: 611,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Het baby’tje wordt in deze doos vervoerd. En hij krijgt een andere, niet-Joodse naam: Willem... zeg maar Wimke. Bekijk deze doos maar eens.',
        model: 'assets/aalten/models/kistje.glb',
        poster: 'assets/aalten/images/kistje-thumbnail.png',
      },
      {
        id: 612,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.928960, lng: 6.583015 },
        range: 15,
        text: 'Loop via de Bredevoortsestraat en Prinsenstraat naar het volgende punt op het Magispad. Via deze kleine steegjes konden veel onderduikers ongemerkt worden opgevangen.',
      },

      // Aalten - Locatie 2

      {
        id: 613,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Kostbaar pakketje',
        url: 'assets/aalten/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over een wel heel kostbaar pakketje.',
      },
      {
        id: 614,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Vindt het juiste huisnummer',
        question: "In Aalten woonde tijdens de Tweede Wereldoorlog zo'n 13.000 mensen, waarvan ongeveer 20% een onderduiker was. Hoeveel mensen zaten er ongeveer ondergedoken?",
        correctIndex: 1,
        options: [
          '2100',
          '2600',
          '2900',
          '3600',
        ],
      },
      /*
      {
        id: 615,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.929144, lng: 6.583613 },
        range: 15,
        text: 'Ga naar de Patrimoniumstraat 12 en loop zachtjes! Niemand mag de baby horen, je kunt niemand vertrouwen.',
      },
      */

      {
        id: 616,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Ome Jan',
        url: 'assets/aalten/videos/Video 4_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de belangrijke verzetsleider.',
      },
      {
        id: 617,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/aalten/audio/Koms een kieke!.mp3',
        title: 'Herinnering',
        text: 'Beluister het geluidsfragment en hoor wat dochter Jo Wikkerink zich herinnert van dat kostbare pakketje voor de deur...',
      },
      /*
      {
        id: 618,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/aalten/images/Huis Wikkerink_toen.jpg',
        outlineImage: 'assets/aalten/images/Huis Wikkerink_toen.jpg',
        title: 'Toen en nu',
        text: "Wil je weten hoe het huis eruitzag in mei 1945? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts. Stel je voor dat zoiets gebeurt met onderduikers in huis! Zie dan maar ongezien weg te komen.",
      },
      */
      {
        id: 619,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Een nieuwe naam',
        url: 'assets/aalten/videos/Video 5_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over baby Wimke.',
      },
      {
        id: 620,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.929144, lng: 6.583613 },
        range: 15,
        text: 'Gebruik je kompas om het gebrandschilderde raam aan de achterkant van de Oosterkerk te vinden.',
      },

      // Aalten - Locatie 3

      {
        id: 621,
        type: ContentType.Puzzle,
        thumbnail: 'assets/aalten/images/Aalten_gedenkraam_Oosterkerk.jpg',
        url: 'assets/aalten/images/Aalten_gedenkraam_Oosterkerk.jpg',
        title: 'Gedenkraam',
        text: 'Los de puzzel om het Gedenkraam voor het Verzet te kunnen bekijken.',
      },
      {
        id: 622,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/Aalten_gedenkraam_Oosterkerk.jpg',
            thumbnail: 'assets/aalten/images/Aalten_gedenkraam_Oosterkerk.jpg',
            title: '',
            text: 'Gedenkraam voor het Verzet',
          },
        ],
        title: 'Gedenkraam',
        text: 'Bekijk het Gedenkraam voor het Verzet. Hierop zie je allemaal beelden over de onderduik in Aalten. Je ziet ook gemeentewapens links- en rechts onderin. Het raam is na de bevrijding geschonken door allerlei gemeenten als dank aan Aalten voor de hulp aan onderduikers.',
      },

      // Aalten - Locatie 3.5 checkpoint
      {
        id: 6232,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.928486, lng: 6.581996 },
        range: 15,
        text: 'Loop via het Magispad om de kerk heen, sla rechts af naar Langs de Gevangenis en steek de Prinsenstraat over naar Frerikspad.',
      },
      {
        id: 623,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.927817, lng: 6.580805 },
        range: 15,
        text: 'Loop via dit pad richting de Markt maar sla ter hoogte van het museum rechtsaf naar Landstraat 41.',
      },

      // Aalten - Locatie 4

      {
        id: 624,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/Stolpersteine gevonden_01.png',
            thumbnail: 'assets/aalten/images/Stolpersteine gevonden_01.png',
            title: 'Stolperstein',
            text: '',
          },
        ],
        title: 'Stolperstein gevonden!',
        text: 'Stolpersteinen, ook wel struikelstenen genoemd, zijn gedenkstenen die geplaatst zijn voor het huis van weggevoerde Joodse bewoners.',
      },
      {
        id: 625,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/Stolpersteine Aalten alle slachtoffers.jpg',
            thumbnail: 'assets/aalten/images/Stolpersteine Aalten alle slachtoffers.jpg',
            title: 'Stolpersteinen',
            text: 'Alle Stolpersteinen in Aalten',
          },
        ],
        title: 'Stolpersteinen',
        text: 'In Aalten liggen meerdere van die struikelstenen. Stolpersteinen is een project van Gunter Demnig. Dat is een Duitse kunstenaar die vindt dat wij uit eerbied moeten buigen voor deze slachtoffers. Een mooie gedachte toch?',
      },
      {
        id: 626,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Dokter Der Weduwen',
        url: 'assets/aalten/videos/Video 6_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over dokter Der Weduwen.',
      },
      {
        id: 627,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.927282, lng: 6.580857 },
        range: 15,
        text: 'Gebruik het kompas en loop door de Landstraat naar het gemeentehuis.',
      },

      // Aalten - Locatie 5

      {
        id: 628,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/geboorteakte_01.png',
            thumbnail: 'assets/aalten/images/geboorteakte gevonden_01.png',
            title: 'Geboorteakte van Wimke',
            text: '',
          },
        ],
        title: 'Voorwerp gevonden!',
        text: 'Je hebt de geboorteakte van Wimke gevonden. Bekijk het eens.',
      },
      {
        id: 629,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Geboorteakte',
        url: 'assets/aalten/videos/Video 7_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de geboorteakte van Wimke.',
      },
      {
        id: 630,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/aalten/audio/NSB vrouw.mp3',
        title: 'Jo Wikkerink',
        text: 'Beluister het audiofragment van dochter Jo Wikkerink (nagespeeld).',
      },
      {
        id: 631,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Na de oorlog',
        url: 'assets/aalten/videos/Video 8_HB.mp4',
        videoThumbnail: '',
        text: 'Terug naar het eigen gezin.',
      },
      {
        id: 632,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.9266, lng: 6.581602 },
        range: 15,
        text: 'Gebruik je kompas en loop naar de Helenakerk.',
      },

      // Aalten - Locatie 6

      {
        id: 633,
        type: ContentType.Video,
        thumbnail: '',
        title: "Kerkrazzia's",
        url: 'assets/aalten/videos/Video 9_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over de kerkrazzia's.",
      },
      {
        id: 634,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/aalten/images/bijbel open_01.jpg',
            thumbnail: 'assets/aalten/images/bijbel gevonden_01.png',
            title: 'Bijbel',
            text: "'Verberg de verdrevene, verraad een vluchteling niet!'",
          },
        ],
        title: 'Voorwerp gevonden!',
        text: 'Je hebt een bijbel gevonden. Open hem door er op te tikken.',
      },
      {
        id: 635,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Onderduikplek',
        url: 'assets/aalten/videos/Video 10_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over een zeer gevaarlijke onderduikplek.",
      },
      {
        id: 636,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.927501, lng: 6.581446 },
        range: 15,
        text: 'Gebruik je kompas en loop naar de Markt 12 (Marktzijde).',
      },

      // Aalten - Locatie 7

      {
        id: 637,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Jennie Kempink',
        url: 'assets/aalten/videos/Video 11_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over Jennie Kempink.",
      },
      // {
      //   id: 638,
      //   type: ContentType.Video,
      //   thumbnail: '',
      //   title: 'Videoboodschap',
      //   url: 'assets/algemeen/placeholders/Samir_PLACEHOLDER.mp4', // TODO moet nog aangeleverd
      //   videoThumbnail: '',
      //   text: "In dit huis, het hol van de leeuw, woonde toen de kleine Jennie. Nu is hier het Nationaal Onderduikmuseum gevestigd. De voormalig directeur van het museum heeft een videoboodschap voor je.",
      // },
      {
        id: 639,
        type: ContentType.Text,
        thumbnail: 'assets/aalten/images/bijbel gevonden_01.png',
        title: 'Meer beleven?',
        text: 'Wil je zelf ervaren hoe de onderduikers in Markt 12 verbleven? Bezoek dan het Nationaal Onderduikmuseum!',
      },
      {
        id: 640,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/algemeen/images/candleholder.png',
        completionText: 'Je hebt alle kaarsen opgestoken ter nagedachtenis van de gevallenen.',
        targets: [
          { id: 0, x: 12, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 1, x: 31, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 2, x: 48, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 3, x: 67, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' }
        ],
        title: 'Virtuele kaars',
        text: 'Steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 641,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Aalten | Multi Content ------------------------------------------------------------------------------------------


    ]
  },

  // GROESBEEK -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  {
    id: 7,
    name: 'Groesbeek',
    cover: 'assets/groesbeek/images/1.jpg',
    category: 6,
    city: 'Groesbeek',
    street: 'Wylerbaan 4',
    zip: '6561 KR',
    length: 5400,
    position: {
      lat: 51.785423,
      lng: 5.935918,
    },
    description: 'Deze lange wandeling brengt je door het voormalige strijdgebied van Operatie Veritable, een van de belangrijkste operaties uit de Tweede Wereldoorlog. Dit was een doorbraak in de bevrijding van West-Europa.',
    content: [

      // Groesbeek - Locatie 1

      {
        id: 701,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Introductie',
        url: 'assets/groesbeek/videos/Video 1_HB.mp4',
        videoThumbnail: '',
        text: 'Samir geeft je een introductie over dit Oorlogspad.',
      },
      {
        id: 702,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [70201, 70202, 70203, 70204],
        title: 'Hoofdrolspelers',
        text: 'Maak kennis met de vier personen die je gaan vergezellen op dit Oorlogspad, daar waar operatie Veritable plaatsvond.',
      },
      {
        id: 703,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.784255, lng: 5.933890 },
        range: 15,
        text: 'Gebruik je kompas en loop naar de bushalte tegenover hotel De Oude Molen.',
      },

      // Groesbeek - Locatie 2

      {
        id: 704,
        type: ContentType.Model,
        thumbnail: '',
        title: 'Voorwerp gevonden!',
        text: 'Nu rijden hier auto’s maar tijdens de Tweede Wereldoorlog reden hier tanks!',
        model: 'assets/groesbeek/models/churchill_tank.glb',
        poster: 'assets/groesbeek/images/tank-thumbnail.png',
      },
      {
        id: 705,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Startlinie',
        url: 'assets/groesbeek/videos/Video 2_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video met Samir.',
      },
      {
        id: 706,
        type: ContentType.BeforeAfter,
        thumbnail: '',
        afterImage: 'assets/groesbeek/images/Britse tanks bij De Oude Molen.jpg',
        outlineImage: 'assets/groesbeek/images/Britse tanks bij De Oude Molen.jpg',
        title: 'Toen en nu',
        text: "Wil je weten hoe het er hier toen uitzag? Match het perspectief, maak een foto en beweeg je vinger over het scherm van links naar rechts.",
      },
      {
        id: 707,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Britse tanks in Nijmegen.jpg',
            thumbnail: 'assets/groesbeek/images/Britse tanks in Nijmegen.jpg',
            title: '',
            text: 'Britse Shermantanks staan voor aanvang van Operation Veritable geparkeerd in een straat in Nijmegen, februari-maart 1945.',
          },
          {
            url: 'assets/groesbeek/images/Britse tanks aan de Wylerbaan.jpg',
            thumbnail: 'assets/groesbeek/images/Britse tanks aan de Wylerbaan.jpg',
            title: '',
            text: 'Britse tanks aan de Wylerbaan in Groesbeek maken zich klaar om Duitsland binnen te vallen, 8 februari 1945.',
          },
          {
            url: 'assets/groesbeek/images/geallieerden verzamelen zich bij startlinie.jpg',
            thumbnail: 'assets/groesbeek/images/geallieerden verzamelen zich bij startlinie.jpg',
            title: '',
            text: 'Geallieerden verzamelen zich bij startlinie',
          },
        ],
        title: 'Fotogalerij',
        text: "Bekijk de foto's van de startlinie.",
      },
      {
        id: 708,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Bombardementen',
        url: 'assets/groesbeek/videos/Video 3_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de bombardementen.',
      },
      {
        id: 709,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Thomas Beresford Big Canoe',
        url: 'assets/groesbeek/videos/Backdrop + Big Canoe Audio_HB.mp4',
        videoThumbnail: '',
        text: 'Hoe vergaat het Thomas Beresford Big Canoe in het leger? Luister het fragment.',
      },
      {
        id: 710,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Start Operatie Veritable',
        url: 'assets/groesbeek/videos/Video 4_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video over de start van Operatie Veritable.',
      },
      {
        id: 711,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Aubrey Cosens',
        url: 'assets/groesbeek/videos/Backdrop + Aubrey Audio_HB.mp4',
        videoThumbnail: '',
        text: 'Op 2 februari, dat is een kleine week vóór de start van operatie Veritable, heeft Aubrey Cosens promotie gemaakt. Hij is nu sergeant. In een brief aan zijn vriendin schrijft hij hierover het volgende:',
      },
      {
        id: 712,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.786173, lng: 5.936551 },
        range: 15,
        text: 'Gebruik je kompas en loop naar het luchtalarm bij het Vrijheidsmuseum (aan de Wylerbaan).',
      },

      // Groesbeek - Locatie 3

      {
        id: 713,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Luchtalarm',
        url: 'assets/groesbeek/videos/Video 5_HB.mp4',
        videoThumbnail: '',
        text: 'Bekijk de video.',
      },
      {
        id: 714,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/groesbeek/audio/Luchtalarm.mp3',
        title: 'Luchtalarm',
        text: 'Zo klonk het luchtalarm in die tijd.',
      },
      {
        id: 715,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/beschutting in schuilkelder Canisiuscollege.jpg',
            thumbnail: 'assets/groesbeek/images/beschutting in schuilkelder Canisiuscollege.jpg',
            title: '',
            text: 'Twaalf personen vinden beschutting tegen het oorlogsgeweld in de schuilkelder van het Canisiuscollege, september 1944. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/mensen bij radio in schuilkelder Canisiuscollege.jpg',
            thumbnail: 'assets/groesbeek/images/mensen bij radio in schuilkelder Canisiuscollege.jpg',
            title: '',
            text: 'Een groep mensen bij een radio in de schuilkelder van het Canisiuscollege, september 1944. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/mensen onder dekens in schuilkelder Canisiuscollege.jpg',
            thumbnail: 'assets/groesbeek/images/mensen onder dekens in schuilkelder Canisiuscollege.jpg',
            title: '',
            text: 'Bijschrift: Mensen onder de dekens in de schuilkelder van het Canisiuscollege, september 1944. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/familie in schuilkelder Canisiuscollege.jpg',
            thumbnail: 'assets/groesbeek/images/familie in schuilkelder Canisiuscollege.jpg',
            title: '',
            text: 'Een familie in de schuilkelder van het Canisiuscollege, november 1944. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/mensen bij noodaltaar in schuilkelder Canisiuscollege.jpg',
            thumbnail: 'assets/groesbeek/images/mensen bij noodaltaar in schuilkelder Canisiuscollege.jpg',
            title: '',
            text: 'Mensen bij het noodaltaar in de schuilkelder van het Canisiuscollege, 1944-1945. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
        ],
        title: 'Schuilkelder',
        text: "Bekijk de foto's van de schuilkelder in het Canisiuscollege.",
      },
      {
        id: 716,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Ida van Marwijk',
        url: 'assets/groesbeek/videos/Backdrop + Ida Audio_HB.mp4',
        videoThumbnail: '',
        text: "De Nijmeegse Ida van Marwijk vlucht met haar ouders naar de schuilkelder van het Wilhelminaziekenhuis, maar daar zijn ze niet veilig. Ze gaan naar het Canisiuscollege en komen daar om twee uur 's nachts ongedeerd aan. Beluister haar verhaal.",
      },
      {
        id: 717,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Even rekenen!',
        question: 'Op 17 september 1944 begint de Slag om Nijmegen en daarmee de bevrijding van de stad. Op 17 maart 1945 valt de laatste Duitse granaat op Nijmegen. Gedurende deze periode is Nijmegen frontstad geweest. Stel dat je die hele periode moet onderduiken in een schuilkelder, hoeveel dagen zit je daar dan?',
        correctIndex: 1,
        options: [
          '183 dagen',
          '182 dagen',
          '181 dagen',
          'Ik zou er niet eens aan beginnen',
        ],
      },
      {
        id: 718,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Evacuatie',
        url: 'assets/groesbeek/videos/Video 6_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over de evacuatie van Groesbeek.",
      },
      {
        id: 719,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Moeizame opmars',
        url: 'assets/groesbeek/videos/Video 7_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over de moeizame opmars.",
      },
      {
        id: 720,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Britse tank rijdt over modderige weg in Reichswald.jpg',
            thumbnail: 'assets/groesbeek/images/Britse tank rijdt over modderige weg in Reichswald.jpg',
            title: '',
            text: 'Een Britse tank rijdt over een modderige weg in het Reichswald, 8 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/Britse troepen trekken door Reichswald.jpg',
            thumbnail: 'assets/groesbeek/images/Britse troepen trekken door Reichswald.jpg',
            title: '',
            text: 'Britse troepen trekken door het Reichswald, 10 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/twee Britse soldaten in Reichswald.jpg',
            thumbnail: 'assets/groesbeek/images/twee Britse soldaten in Reichswald.jpg',
            title: '',
            text: 'Twee Britse soldaten in het Reichswald, 9 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/Canadese militairen vuren kanonnen af in Reichswald.jpg',
            thumbnail: 'assets/groesbeek/images/Canadese militairen vuren kanonnen af in Reichswald.jpg',
            title: '',
            text: 'Canadese militairen vuren hun kanonnen af in het Reichswald, 8 februari-maart 1945. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/Reichswald.jpg',
            thumbnail: 'assets/groesbeek/images/Reichswald.jpg',
            title: '',
            text: 'Britse soldaten in het gehavende Reichswald, circa 10-12 februari 1945. Bron: Vrijheidsmuseum',
          },
          {
            url: 'assets/groesbeek/images/Onderwater gelopen.jpg',
            thumbnail: 'assets/groesbeek/images/Onderwater gelopen.jpg',
            title: '',
            text: 'Britse troepen banen zich een weg door het onder water staande Duitse dorp Kranenburg, 11 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/Britse collonne trekt door Kleef.jpg',
            thumbnail: 'assets/groesbeek/images/Britse collonne trekt door Kleef.jpg',
            title: '',
            text: 'Een Britse colonne trekt door Kleef nadat bulldozers de straten puinvrij hebben gemaakt, 11 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/luchtfoto van zwaar beschadigd Kleef.jpg',
            thumbnail: 'assets/groesbeek/images/luchtfoto van zwaar beschadigd Kleef.jpg',
            title: '',
            text: 'Luchtfoto van het zwaar beschadigde Kleef, genomen daags na de val van de stad, 12- 16 februari 1945. Bron: fotocollectie Regionaal Archief Nijmegen ',
          },
          {
            url: 'assets/groesbeek/images/Canadese militairen en tanks staan klaar om Goch aan te vallen.jpg',
            thumbnail: 'assets/groesbeek/images/Canadese militairen en tanks staan klaar om Goch aan te vallen.jpg',
            title: '',
            text: 'Canadese militairen en tanks staan klaar om Goch aan te vallen, 17 februari 1945. Bron: IWM',
          },
        ],
        title: 'Moeizame opmars',
        text: "Bekijk de foto's van de moeizame opmars.",
      },
      {
        id: 721,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Heinz Joachim Ollenburg',
        url: 'assets/groesbeek/videos/Backdrop + Heinz Audio_HB.mp4',
        videoThumbnail: '',
        text: "De soldaat van de Duitse luchtmacht, schrijft over 9 en 10 februari, de eerste twee dagen na de start van operatie Veritable, deze dagboekaantekeningen...",
      },
      {
        id: 722,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.795238, lng: 5.950059 },
        range: 15,
        text: 'Gebruik je kompas en loop naar het monument van de 82nd Airborne Division en Operatie Veritable (hoek Wylerbaan-Derdebaan). Dit is een wandeling van pakweg 20 minuten.',
      },

      // Groesbeek - Locatie 4

      {
        id: 723,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/groesbeek/audio/Video 8.mp3',
        title: 'Dubbelmonument',
        text: 'Beluister het geluidsfragment van Samir.',
      },
      {
        id: 724,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Amerikaanse parachutisten landen nabij Groesbeek.jpg',
            thumbnail: 'assets/groesbeek/images/Amerikaanse parachutisten landen nabij Groesbeek.jpg',
            title: '',
            text: 'Amerikaanse parachutisten van de 82e luchtlandingsdivisie van het Amerikaanse leger landen in het kader van operatie Market Garden nabij Groesbeek, 17-18 september 1944. Bron: fotocollectie Regionaal Archief Nijmegen ',
          },
          {
            url: 'assets/groesbeek/images/Montgomery en Crerar.jpg',
            thumbnail: 'assets/groesbeek/images/Montgomery en Crerar.jpg',
            title: '',
            text: 'De Britse veldmaarschalk Bernard Montgomery (rechts) met de Canadese generaal Harry Crerar na een conferentie in Nijmegen, februari 1945. Bron: fotocollectie Regionaal Archief Nijmegen',
          },
        ],
        title: 'Fotogalerie',
        text: "Bekijk de foto's.",
      },
      {
        id: 725,
        type: ContentType.Puzzle,
        thumbnail: 'assets/groesbeek/images/puzzle.jpg',
        url: 'assets/groesbeek/images/puzzle.jpg',
        title: 'Puzzle',
        text: 'Probeer deze ‘verknipte’ foto te herstellen. Schuiven maar!',
      },
      {
        id: 726,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/puzzle.jpg',
            thumbnail: 'assets/groesbeek/images/puzzle.jpg',
            title: '',
            text: 'Op deze foto die genomen is op 8 februari 1945 zie je een mijnenopruimtank op een Groesbeeks weiland bij de start van operatie Veritable, terwijl op de achtergrond een achtergebleven zweefvliegtuig te zien is dat in september 1944 op dit grasland is geland in het kader van de Amerikaanse luchtlandingen tijdens operatie Market Garden.',
          },
        ],
        title: 'Het is je gelukt!',
        text: "Op deze foto die genomen is op 8 februari 1945 zie je een mijnenopruimtank op een Groesbeeks weiland bij de start van operatie Veritable, terwijl op de achtergrond een achtergebleven zweefvliegtuig te zien is dat in september 1944 op dit grasland is geland in het kader van de Amerikaanse luchtlandingen tijdens operatie Market Garden.",
      },
      {
        id: 727,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Heinz Joachim Ollenburg',
        url: 'assets/groesbeek/videos/Backdrop + Heinz Audio 2_HB.mp4',
        videoThumbnail: '',
        text: "De groep van Heinz Joachim Ollenburg van de Duitse luchtmacht heeft ondertussen een kleine houten bunker gebouwd om in te slapen. Op 12 februari staat Ollenburg meerdere uren op de uitkijk, zo vermeldt hij in zijn dagboekaantekeningen.",
      },
      {
        id: 728,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Operaties in het Rijnland',
        url: 'assets/groesbeek/videos/Video 9_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over de operaties in het Rijnland.",
      },
      {
        id: 729,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Britse grondtroepen trekken door Uedem.jpg',
            thumbnail: 'assets/groesbeek/images/Britse grondtroepen trekken door Uedem.jpg',
            title: '',
            text: 'Britse grondtroepen trekken door het gehavende Duitse plaatsje Uedem, februari-maart 1945. Bron: Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/luchtfoto van gehavend Kalkar.jpg',
            thumbnail: 'assets/groesbeek/images/luchtfoto van gehavend Kalkar.jpg',
            title: '',
            text: 'Luchtfoto van de gehavende Duitse plaats Kalkar, 16 februari-maart 1945. Bron: Regionaal Archief Nijmegen',
          },
          {
            url: 'assets/groesbeek/images/Xanten.jpg',
            thumbnail: 'assets/groesbeek/images/Xanten.jpg',
            title: '',
            text: 'Xanten valt: de geallieerden veroveren een stad die in puin ligt, 11 maart 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/Tekening van geallieerde troepen die Duits fort aanvallen.jpg',
            thumbnail: 'assets/groesbeek/images/Tekening van geallieerde troepen die Duits fort aanvallen.jpg',
            title: '',
            text: 'Tekening, gemaakt door de Britse oorlogscorrespondent Bryan de Grineau, van geallieerde troepen die op weg naar Wesel een Duits fort aanvallen, 8 februari-maart 1945. Bron: Regionaal Archief Nijmegen',
          },
        ],
        title: 'Fotogalerie',
        text: "Bekijk de foto's.",
      },
      {
        id: 730,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Aubrey Cosens',
        text: 'Hoe vergaat het sergeant Aubrey Cosens ondertussen? De eenheid waar hij deel van uitmaakt valt in de nacht van 25 op 26 februari het Duitse plaatsje Mooshof aan. Dat ligt tussen Uedem en Kalkar. De verovering van deze grond wordt als heel belangrijk gezien voor de toekomstige operaties. Een editie van de London Gazette – het staatsblad van het Verenigd Koninkrijk – uit mei 1945 beschrijft hoe het Cosens die nacht vergaat. Ga verder om het artikel te beluisteren.',
      },
      {
        id: 731,
        type: ContentType.Audio,
        thumbnail: '',
        url: 'assets/groesbeek/audio/Artikel London Gazette.mp3',
        title: 'Artikel London Gazette',
        text: 'Beluister het artikel.',
      },
      {
        id: 732,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Britse soldaten bij Duitse boerderij.jpg',
            thumbnail: 'assets/groesbeek/images/Britse soldaten bij Duitse boerderij.jpg',
            title: '',
            text: 'Britse soldaten bij een Duitse boerderij, 25 februari 1945. Bron: IWM',
          },
          {
            url: 'assets/groesbeek/images/Britse militair kijkt naar opschrift op Duitse boerderij.jpg',
            thumbnail: 'assets/groesbeek/images/Britse militair kijkt naar opschrift op Duitse boerderij.jpg',
            title: '',
            text: "Britse militair die naar een opschrift op een Duitse boerderij kijkt, 25 februari 1945. Dit opschrift luidt 'Tod den Mördern unserer Heimat', oftewel 'Dood aan de moordenaars van ons vaderland'. Bron: IWM",
          },
        ],
        title: 'Fotogalerie',
        text: "Bekijk de foto's.",
      },
      {
        id: 733,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.795922, lng: 5.935982 },
        range: 15,
        text: 'Gebruik je kompas en loop naar de boom van Horrocks. Dit is een wandeling van pakweg 15 minuten.',
      },

      // Groesbeek - Locatie 5

      {
        id: 734,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Boom van Horrocks',
        question: 'Je staat nu bij de zogenaamde Boom van Horrocks. Waarom heet deze boom zo? Omdat de Britse luitenant-generaal Brian Horrocks...',
        correctIndex: 0,
        options: [
          '...van deze boom zijn uitkijkpost maakte.',
          '...kort na de oorlog deze boom plantte als bedankje aan de houders van deze boerderij voor het ter beschikking stellen van de weilanden aan de geallieerde troepen. ',
          '...aan de dood wist te ontsnappen door achter deze boom dekking te zoeken toen Duitse troepen een verrassingsaanval plaatsten. ',
        ],
      },
      {
        id: 735,
        type: ContentType.Text,
        thumbnail: '',
        title: 'Boom van Horrocks',
        text: 'De boom heet inderdaad zo, omdat Horrocks er zijn uitkijkpost van maakte. Van hieruit had hij goed zicht op de Groesbeekse velden én op het Duitse Reichswald. Hij klom erin en gaf bevel tot de start van operatie Veritable op 8 februari 1945. De houten trap en het houten schot in de boom geven een beeld van de uitkijkpost zoals deze er toen ongeveer moet hebben uitgezien.',
      },
      {
        id: 736,
        type: ContentType.Image,
        thumbnail: '',
        images: [
          {
            url: 'assets/groesbeek/images/Brian Horrocks.jpg',
            thumbnail: 'assets/groesbeek/images/Brian Horrocks.jpg',
            title: 'Brian Horrocks',
            text: 'De Britse luitenant-generaal Brian Horrocks in Noord-Afrika tijdens de Tweede Wereldoorlog. Bron: IWM',
          },
        ],
        title: 'Brian Horrocks',
        text: 'De Britse luitenant-generaal Brian Horrocks in Noord-Afrika tijdens de Tweede Wereldoorlog.',
      },
      {
        id: 737,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Thomas Beresford Big Canoe',
        url: 'assets/groesbeek/videos/Backdrop + Big Canoe Audio 2_HB.mp4',
        videoThumbnail: '',
        text: "Hoe zou het gaan met Thomas Beresford Big Canoe? Beluister het fragment.",
      },
      {
        id: 738,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Operatie Plunder',
        url: 'assets/groesbeek/videos/Video 10_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over operatie Plunder.",
      },
      {
        id: 739,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Heinz Joachim Ollenburg',
        url: 'assets/groesbeek/videos/Backdrop + Heinz Audio 3_HB.mp4',
        videoThumbnail: '',
        text: "Een van die Duitse krijgsgevangenen is Heinz Joachim Ollenburg. Op 16 februari bevindt hij zich in een huis in Hommersum, een dorp bij de grens met Nederland ter hoogte van Goch. Beluister zijn dagboekaantekeningen.",
      },
      {
        id: 740,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.797720, lng: 5.930790 },
        range: 15,
        text: 'Gebruik je kompas en loop in tien minuten naar het Groesbeek Canadian War Cemetery & Memorial.',
      },

      // Groesbeek - Locatie 6

      {
        id: 741,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Groesbeek Canadian War Cemetery & Memorial',
        url: 'assets/groesbeek/videos/Video 11_HB.mp4',
        videoThumbnail: '',
        text: "Bekijk de video over deze bijzondere begraafplaats.",
      },
      {
        id: 742,
        type: ContentType.Navigate,
        thumbnail: '',
        targetPosition: { lat: 51.785423, lng: 5.935918 },
        range: 15,
        text: 'Gebruik je kompas en wandel langs de Zevenheuvelenweg terug naar het Vrijheidsmuseum voor het eind van dit Oorlogspad.',
      },
      // eindlocatie

      // {
      //   id: 742,
      //   type: ContentType.Video,
      //   thumbnail: '',
      //   title: 'Videoboodschap',
      //   url: 'assets/groesbeek/videos/Video 11_HB.mp4', // TODO MOET NOG WORDEN AANGELEVERD
      //   videoThumbnail: '',
      //   text: "Bekijk de video met Wiel Lenders, de voormalige directeur en oprichter van het Vrijheidsmuseum.",
      // },
      {
        id: 743,
        type: ContentType.Multi,
        thumbnail: '',
        contentId: [74301, 74302],
        title: 'Keuzemenu',
        text: 'Maak een foto en/of steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 744,
        type: ContentType.RouteComplete,
        thumbnail: '',
        title: 'Einde route',
        text: 'Ga naar het hoofdmenu en kies een van de andere Oorlogspaden in Gelderland! Dit oorlogspad werd mede mogelijk gemaakt door Mondriaanfondsen.',
        url: 'assets/algemeen/images/flag-30deg-flare.png'
      },

      // Groesbeek | Multi Content ------------------------------------------------------------------------------------------

      {
        id: 70201,
        type: ContentType.Text,
        thumbnail: 'assets/groesbeek/images/Aubrey Cosens.jpg',
        title: 'Aubrey Cosens',
        text: 'Dit is Aubrey Cosens. Geboren in het dorpje Latchford in de Canadese provincie Ontario. Cosens moeder overlijdt wanneer hij slechts vier jaar oud is. Zijn vader is een spoorwegarbeider die heeft gevochten in de Eerste Wereldoorlog. Cosens gaat zelf in 1940 bij het leger. Hij maakt promotie van corporaal naar sergeant. Wanneer operatie Veritable van start gaat, is hij 23 jaar oud.',
      },
      {
        id: 70202,
        type: ContentType.Text,
        thumbnail: 'assets/groesbeek/images/Thomas Beresford Big Canoe.jpg',
        title: 'Thomas Beresford Big Canoe',
        text: 'Dit is de Canadees Thomas Beresford Big Canoe. Geboren op Georgina Island in een familie die deel uitmaakt van de Chippewa-gemeenschap, onderdeel van een inheemse bevolkingsgroep. In juni 1944 meldt hij zich bij het leger. Hij is op dat moment 18 jaar.',
      },
      {
        id: 70203,
        type: ContentType.Text,
        thumbnail: 'assets/groesbeek/images/groep Fallschirmjäger.jpg', // TODO
        title: 'Heinz Joachim Ollenburg',
        text: 'Heinz Joachim Ollenburg zit tijdens de oorlog in het Duitse leger en maakt deel uit van de Fallschirmjäger oftewel parachutisten. In deze hoedanigheid vecht hij tegen de geallieerde troepen. Hij maakt dagboekaantekeningen over deze strijd.',
      },
      {
        id: 70204,
        type: ContentType.Text,
        thumbnail: 'assets/groesbeek/images/Ida-anoniem.jpg',
        title: 'Ida van Marwijk',
        text: 'De Nijmeegse Ida van Marwijk is veertig jaar oud wanneer Nazi-Duitsland in mei 1940 Nederland binnenvalt. Ze houdt een dagboek bij.',
      },

      //---------------------------------------------------------------------------------------------------------

      {
        id: 74301,
        type: ContentType.Tap,
        thumbnail: '',
        background: 'assets/algemeen/images/candleholder.png',
        completionText: 'Je hebt alle kaarsen opgestoken ter nagedachtenis van de gevallenen.',
        targets: [
          { id: 0, x: 12, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 1, x: 31, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 2, x: 48, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' },
          { id: 3, x: 67, y: 15, size: 20, image: 'assets/algemeen/images/candlelight.png' }
        ],
        title: 'Virtuele kaars',
        text: 'Steek de kaarsen aan ter nagedachtenis aan de slachtoffers.',
      },
      {
        id: 74302,
        type: ContentType.Share,
        thumbnail: '',
        text: 'Maak een selfie',
        title: 'Foto maken',
      },

    ]
  },

];

// ----------------------------------------------------------------------

export const route = (index) => routes.find(r => r.id === parseInt(index, 10));
