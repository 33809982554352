/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import { React, useEffect, useState, useCallback, useRef } from 'react'
import { isDesktop, isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import compassBackground from './images/Compass.svg';
import compassInner from './images/Binnen ring.svg';
import compassOuter from './images/Buiten ring.svg';
import compassStart from './images/compass-start.svg';
import compassError from './images/compass-error.svg';


const Image = styled('img')({
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    width: '100%',
});

function Compass({ hasPosition, headingDegrees, deniedCallback }) {

    const [compass, setCompass] = useState(0);
    const [started, setStarted] = useState(false);
    const [denied, setDenied] = useState(false);
    const [supported, setSupported] = useState(true);

    const gyroUpdates = useRef(0);

    const startSafariCompass = useCallback(() => {
        if (isIOS && !started) {
            if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceOrientationEvent.requestPermission) === "function") {
                DeviceOrientationEvent.requestPermission()
                    .then((response) => {
                        if (response === "granted") {
                            window.removeEventListener("deviceorientation", handleIOS);
                            window.addEventListener("deviceorientation", handleIOS, true);
                            setStarted(true);
                        } else {
                            console.log("iOS orientation not allowed.");
                            setDenied(true);
                            setSupported(false); // show unsupported message on compass, as permission can't be retried.
                            if(deniedCallback) {
                                deniedCallback();
                            }
                        }
                    })
                    .catch(() => console.log("iOS orientation failed."));
            } else {
                // iOS < 13 ?
                window.removeEventListener("deviceorientation", handleIOS);
                window.addEventListener("deviceorientation", handleIOS, true);
                setStarted(true);
            }
        }

    }, [deniedCallback, started]);


    const onGyro = useCallback((event) => {
        if (event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma) {
            if (!isIOS) {
                window.addEventListener("deviceorientationabsolute", handleNormal, true);
                setStarted(true);
                window.removeEventListener("devicemotion", onGyro);
            }
        } else {
            // don't remove listener on first event, as first event on firefox might not contain rotationRate
            if (gyroUpdates.current > 3) {
                setSupported(false);
                window.removeEventListener("devicemotion", onGyro);
            }
            gyroUpdates.current += 1;
        }
        
    }, [setStarted, setSupported]);


    useEffect(() => {
        if (!started) {
            // try to auto start compass
            if (!isIOS) {
                if (supported) {
                    if (!isDesktop) {
                        window.removeEventListener("deviceorientationabsolute", handleNormal);

                        window.removeEventListener("devicemotion", onGyro);
                        window.addEventListener("devicemotion", onGyro);
                    } else {
                        setSupported(false);
                    }
                }
            }
            else {
                startSafariCompass();
            }
        }

    }, [started, supported, startSafariCompass, onGyro]);



    useEffect(() => () => {
        console.log("cleanup compass");

        if (isIOS) {
            window.removeEventListener("deviceorientation", handleIOS);
        } else {
            window.removeEventListener("deviceorientationabsolute", handleNormal);
        }
    }, []);


    function handleIOS(e) {
        setCompass(e.webkitCompassHeading);
    }

    function handleNormal(e) {
        if (e.absolute) {
            setCompass(Math.abs(e.alpha - 360));
        }
    }




    return (<>
        <Image src={compassBackground} className={"unclickable unselectable"} alt="compass background" style={{ transform: `rotate(${-compass}deg)` }} />
        <Image src={compassInner} className={"unclickable unselectable"} alt="compass direction" />
        {hasPosition ?
            <Image src={compassOuter} className={"unclickable unselectable"} alt="compass target" style={{ transform: `rotate(${headingDegrees - compass}deg)` }} /> : <></>
        }
        {isIOS && !started && supported && !denied ?
            <Image src={compassStart} className={"unselectable"} onClick={() => startSafariCompass()} alt="compass start" style={{ pointerEvents: 'auto' }} /> : <></>}
        {!supported ?
            <Image src={compassError} className={"unselectable unclickable"} alt="compass error" /> : <></>}
    </>);
}

Compass.propTypes = {
    hasPosition: PropTypes.bool,
    headingDegrees: PropTypes.number,
    deniedCallback: PropTypes.func,
};


export default Compass;