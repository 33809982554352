import { useState } from "react";

// @mui
import { Box, Typography, Stack, Button, Container } from '@mui/material';
import { isIOS } from "react-device-detect";

import { AlertDialog } from '../../../components/dialog';

import { ButtonsContainer, Overline } from '../../../components/content';


// ----------------------------------------------------------------------

export default function ContentQuiz(content) {
  const { question, correctIndex, options, onComplete, fromMulti, multiTitle } = content;

  const [isComplete, setCompleteState] = useState(false);

  const [lastClicked, setLastClicked] = useState(-1);

  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [wrongDialogState, SetWrongDialogState] = useState(false); // wrong answer Dialog

  const completionText = 'Dat klopt, goed bezig!';

  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
  const buttons = [];
  for (let i = 0; i < options.length; i += 1) {
    const option = options[i];
    buttons.push(
      <Box key={option}
        sx={{
          '&:hover': isIOS || isComplete ? {} : { cursor: 'pointer', transform: 'scale(1.02)' }, // avoid safari hover bug
          width: '100%',
          maxWidth: '600px',
          background: lastClicked === i ? (i === correctIndex ? '#2A302A' : '#342B2A') : (theme) => theme.palette.background.alternative,
          mb: '8px',
          p: '12px',
          borderRadius: '8px',
          boxShadow: '0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%)',
          border: 'solid 1px transparent',
          borderColor: lastClicked === i ? (i === correctIndex ? '#50994B' : '#CC5652') : 'transparent',
          transition: '0.2s',
        }}
        onClick={() => { if (!isComplete || i === correctIndex) { SelectOption(i); } }}>
        <Stack sx={{ flexDirection: 'row' }}>
          <Box sx={{ flex: '0 1' }}>
            <Box sx={{
              height: '42px',
              width: '42px',
              background: '#474747',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              fontWeight: '500',
              mr: '12px',
              lineHeight: '1',
              color: 'primary.main',
            }}>
              {letters[i]}
            </Box>
          </Box>
          <Typography variant="body2" sx={{ lineHeight: '1.666', display: 'flex', alignItems: 'center' }}> {option} </Typography>
        </Stack>

      </Box>
    )
  };

  function SelectOption(index) {
    setLastClicked(index);
    if (index === correctIndex) {
      SetCompleteDialogState(true);
      setCompleteState(true);
    }
    else {
      SetWrongDialogState(true);
    }
  }

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {fromMulti ?
              <Overline>
                {multiTitle}
              </Overline>
              : null}
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {question}
            </Typography>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <Stack sx={{ flexDirection: { xs: "column" }, flexGrow: 1, alignItems: 'center' }}>
              {buttons}
            </Stack>
          </Container>
          <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "text"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar keuzemenu" : (isComplete ? "Verder" : "Overslaan")}</Button>
          </ButtonsContainer>
        </Stack>
      </Box>

      {/* correct dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />

      {/* wrong answer dialog */}
      <AlertDialog open={wrongDialogState} handleClose={() => SetWrongDialogState(false)} title="Verkeerd"
        content="Dit is niet het juiste antwoord."
        primaryButtonText="Opnieuw proberen" secondaryButtonText=""
        primaryButtonFunc={() => { SetWrongDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}
