import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  top: 0,
  position: 'absolute',
  display: 'flex',
});


export const Container = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  overflow: 'hidden',
  display: 'flex',
});


export const Video = styled('video')(({ safari = false }) => ({
  position: 'relative',
  '@media (orientation: landscape)': {
    width: '100%'
  },
  '@media (orientation: portrait)': {
    width: '100%',
  },
  objectFit: `${ safari ? 'cover' : 'contain' }`, // temp fix for ipad, check if cover works on other platforms 

  '&::-webkit-media-controls-play-button': {
    display: 'none !important',
    // '-webkit-appearance': 'none',
    WebkitAppearance: 'none',
  },
  
  transform: 'translate3d(0,0,0)', // safari fix for mixBlendMode
}));


export const Overlay = styled('img')({
  objectFit: 'contain',
  width: '100%',
  // opacity: '0.60',
  filter: 'grayscale(100%) contrast(150%)',
  WebkitFilter: 'grayscale(100%) contrast(150%)', // Safari fallback
  mixBlendMode: 'multiply',

   transform: 'translate3d(0,0,0)', // safari fix for mixBlendMode
});

