import { styled } from '@mui/material/styles';

import { IconButton } from '@mui/material';

const CarouselNavigationButton = styled(IconButton)({
  position: 'absolute',
  color: 'primary',
  background: '#1C1C1C',
  borderRadius: '100%',
  ":hover": {
    background: '#000'
  }
});

export default CarouselNavigationButton;