import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonsContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '480px !important',
  alignSelf: 'center'
});

export default ButtonsContainer;