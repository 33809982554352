// import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import { formatUrl } from "../../utils/formatUrl";
//
// import Header from './header';
// import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  backgroundImage: `url(${formatUrl('/assets/algemeen/images/background.jpg')})`,
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


// ----------------------------------------------------------------------

export default function ContentPageLayout() {
  return (
    <StyledRoot>
      <Main style={{ padding: '0px' }}>
        <StyledContainer sx={{ p: '0px !important', height: '100%' }}>
          <Grid sx={{ height: '100%' }} container>
            <Outlet />
          </Grid>
        </StyledContainer>
      </Main>
    </StyledRoot>
  );
}
