import { useState, useEffect } from "react";

// @mui
import { Box, Stack, Container, Button } from '@mui/material';

import { TapImageButton } from '../../../components/tap';

import { AlertDialog } from '../../../components/dialog';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

export default function ContentTap(content) {
  const { title, text, background, completionText, targets, onComplete, fromMulti, multiTitle } = content;

  // Keep track of the state of the image buttons inside an array
  const [imageButtonStates, setImageButtonStates] = useState(Array(targets.length).fill(false));

  const imageButtons = getImageButtons(targets);

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog

  // Whenever a state is changed, check if the game is completed
  useEffect(() => {
    function isGameComplete() {
      return imageButtonStates.every(v => v === true);
    }

    if (!isComplete && isGameComplete()) {
      setCompleteState(true);

      setTimeout(() => {
        if (!completeDialogState) {
          SetCompleteDialogState(true);
        }
      }, 1000);
    }

  }, [completeDialogState, imageButtonStates, isComplete, onComplete]);

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <Box style={{ alignItems: 'center', display: 'flex', p: '16px !important', justifyContent: 'center', position: 'relative', aspectRatio: '1 / 1', maxHeight: '40vh', maxWidth: '100vw' }}>
            <img
              src={formatUrl(background)}
              className={"unclickable unselectable"}
              alt="background"
              loading="lazy" />
            {imageButtons}
          </Box>
          <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "text"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar keuzemenu" : (isComplete ? "Verder" : "Overslaan")}</Button>
          </ButtonsContainer>
        </Stack>
      </Box>

      {/* skip dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );

  function handleClick(i) {
    if (isComplete) {
      if (!completeDialogState) {
        SetCompleteDialogState(true);
      }
      return;
    }

    const newImageButtons = imageButtonStates.slice(); // Create a copy of the bool array
    newImageButtons[i] = !newImageButtons[i]; // Toggle the bool
    setImageButtonStates(newImageButtons); // Update all imageButtons state
  }

  function getImageButtons(targets) {
    const targetElements = [];
    for (let i = 0; i < targets.length; i += 1) {
      targetElements.push(<TapImageButton key={`image-button-${i}`} value={imageButtonStates[i]} x={targets[i].x} y={targets[i].y} size={targets[i].size} image={formatUrl(targets[i].image)} onClick={() => handleClick(i)} />);
    }
    return targetElements;
  }
}
