import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, Stack, Paper } from '@mui/material';

import { Link as RouterLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
// utils

// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRouteImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.appbar,
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

RouteCard.propTypes = {
  route: PropTypes.object,
};

export default function RouteCard({ route }) {
  const { name, cover, city } = route;
  const href = `../route/${route.id}`;

  return (
    <Card sx={{ transition: '0.2s', "&:hover": { transform: 'scale(1.02)' } }}>
      <RouterLink to={href} className={"unselectable"} style={{ textDecoration: 'none' }}>
        <Item>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            <StyledRouteImg className={"unselectable"} onLoad={() => { }} alt={name} src={cover} sx={{ filter: 'grayscale(1) sepia(0.1)' }} />
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
              <Typography variant="h3" noWrap>
                {name}
              </Typography>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <div style={{ display: 'flex' }}>
                <Iconify icon="ic:outline-place" sx={{ color: 'primary.main' }} width={22} height={22} />
                <Typography variant="body3" sx={{ pl: 1, color: '#C7C7C7' }}>{city}</Typography>
              </div>
            </Stack>
          </Stack>
        </Item>
      </RouterLink>
    </Card>
  );
}
