
// @mui
import { Box, Stack, Container, Button } from '@mui/material';

import { useRef, useState, useCallback, useEffect, useImperativeHandle } from "react";

import Lightbox from "yet-another-react-lightbox";
// eslint-disable-next-line import/no-unresolved
import Captions from "yet-another-react-lightbox/plugins/captions";


// eslint-disable-next-line import/no-unresolved
import Zoom from "yet-another-react-lightbox/plugins/zoom";

// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/plugins/captions.css";

import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
  useDragToScroll,
} from 'react-snaplist-carousel';

import { isMobile } from 'react-device-detect';
import { CarouselImage } from '../../../components/image';

import { CarouselNavigationButton } from '../../../components/carousel';

import Iconify from "../../../components/iconify";

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';

import '../../../components/image/styles.css';

import useResponsive from '../../../hooks/useResponsive';
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

export default function ContentImage(content) {

  const { images, title, text, onComplete, fromMulti, multiTitle, contentRef } = content;
  const snapList = useRef(null);

  const goToElement = useScroll({ ref: snapList });
  useDragToScroll({ ref: snapList });

  const useRelativePath = true;

  const isDesktop = useResponsive('up', 'lg');

  const [lightboxState, setLightboxState] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const lightboxCaptionsRef = useRef(null);

  const selected = useVisibleElements(
    { ref: snapList, debounce: 100 },
    elements => elements[0],
  );


  const selectImage = useCallback((i, animated = true) => {
    setCurrentImage(i);
    goToElement(i, { animationEnabled: animated });
    console.log('current image', i);
  }, [setCurrentImage, goToElement]);

  // reset to 0 on image list change, to reset scroll on back-to-back ContentImage blocks
  useEffect(() => {
    selectImage(0, false);
  }, [selectImage, images]);



  const imageComponents = getImageComponents(images);

  const lightboxUrl = images.map(img => ({ src: getImageUrl(img), title: img.title, description: img.text }));

  const captionsBackgroundClass = "captions-background-gradient";

  // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if (lightboxState) {
        setLightboxState(false);
        return true;
      }
      return false;
    }

  }), [lightboxState])

  return (
    <>
      {/* <OrientationOverlay/> */}

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          {imageComponents.length > 1 ?
            <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>

              <SnapList
                ref={snapList}
                tabIndex={0} // so it can receive focus and can be scrolled with keyboard
                role="region" // context for screen readers
                aria-label="carousel" // for screen readers to read out loud on focus
                className="carousel"
                style={{ flex: '2', scrollPadding: '0px', padding: '0px 12px', WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)', maskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)' }}
              >
                {imageComponents}
              </SnapList>

              {!isMobile &&
                // hide scroll buttons if mobile touchscreen
                <>
                  {currentImage !== 0 &&
                    <CarouselNavigationButton onClick={() => previousImage()} sx={{ left: '8px' }} aria-label="previous content">
                      <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-before' />
                    </CarouselNavigationButton>
                  }

                  {currentImage < images.length - 1 &&
                    <CarouselNavigationButton onClick={() => nextImage()} sx={{ right: '8px' }} aria-label="next content">
                      <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-next' />
                    </CarouselNavigationButton>
                  }

                </>
              }

            </Container>
            :
            <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>
              <CarouselImage selected multiple={false} onClick={() => { setLightboxState(true); selectImage(0); }} image={encodeURI(getThumbnailImageUrl(images[0]))} />
            </Container>
          }
          <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { onComplete() }}>
              {fromMulti ? "Terug naar keuzemenu" : "Verder"}
            </Button>
          </ButtonsContainer>
        </Stack>
      </Box>

      <Lightbox
        open={lightboxState}
        close={() => setLightboxState(false)}
        index={currentImage}
        slides={lightboxUrl}
        carousel={{
          finite: images.length <= 1
        }}
        toolbar={{
          buttons: [
            // define button order in toolbar
            isDesktop ? "captions" : null,
            "zoom",
            "close",
          ],
        }}
        captions={{
          showToggle: isDesktop, // show iconCaptionsVisible /Hidden
          ref: lightboxCaptionsRef,
          descriptionTextAlign: "start",
          descriptionMaxLines: 3,
        }}
        on={{
          click: () => {
            if (lightboxCaptionsRef.current?.visible) {
              lightboxCaptionsRef.current?.hide();
              toggleCaptionsGradientBackground(false);
            } else {
              lightboxCaptionsRef.current?.show();
              toggleCaptionsGradientBackground(true);
            }

            console.log(lightboxCaptionsRef);
          },
        }}
        render={{
          buttonPrev: images.length > 1 ? undefined : () => null,
          buttonNext: images.length > 1 ? undefined : () => null,
          iconCaptionsVisible: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:baseline-subtitles' />,
          iconCaptionsHidden: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:baseline-subtitles-off' />,
          iconZoomIn: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='mdi:magnify-plus-outline' />,
          iconZoomOut: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='mdi:magnify-minus-outline' />,
          iconClose: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:close' />
        }}
        plugins={[Zoom, Captions]}
        styles={{ root: { "--yarl__icon_size": "24px" } }}
        className={captionsBackgroundClass}
      />
    </>
  );

  function toggleCaptionsGradientBackground(state) {
    const yarlRoot = document.getElementsByClassName("yarl__root")[0];

    if (state && !yarlRoot.classList.contains(captionsBackgroundClass)) {
      yarlRoot.classList.add(captionsBackgroundClass);
    }
    else if (yarlRoot.classList.contains(captionsBackgroundClass)) {
      yarlRoot.classList.remove(captionsBackgroundClass);
    }
  }

  function getImageComponents(items) {
    const carouselItems = [];
    for (let i = 0; i < items.length; i += 1) {
      carouselItems.push(
        <SnapItem key={i} margin={{ left: i === 0 ? '20vw' : '12px', right: i === items.length - 1 ? '20vw' : '12px', }} snapAlign="center">
          <CarouselImage selected={currentImage === i} multiple={images.length > 1} onClick={() => { setLightboxState(true); selectImage(i); }} image={encodeURI(getImageUrl(images[i]))} />
        </SnapItem>
      );
    }
    return carouselItems;
  }

  function getImageUrl(imageObj) {
    if (useRelativePath) {
      return formatUrl(imageObj.url);
    }
    else {
      return imageObj.url;
    }
  }

  function getThumbnailImageUrl(imageObj) {
    if (imageObj.thumbnail) {
      if (useRelativePath) {
        return formatUrl(imageObj.thumbnail);
      }
      else {
        return imageObj.thumbnail;
      }
    } else {
      return getImageUrl(imageObj);
    }
  }

  function nextImage() {
    selectImage(currentImage + 1);
  }

  function previousImage() {
    selectImage(currentImage - 1);
  }

}
