import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

const QrReader = lazy(() => import('./qr-reader'));

const QR = ({ onScan }) => (
  <div style={{ height: '100%', width: '100%' }}>
    <Suspense fallback={<div style={{
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    }} />
    }>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        containerStyle={{
          width: '100%', height: '100%',
        }}
        videoStyle={{
          objectFit: 'cover'
        }}
        onResult={(result) => {
          if (result && result.text) {
            if (isCurrentSite(result.text) || isDomainMatch(result.text)) {
              let url = result.text;
              while (url.endsWith('/')) {
                url = url.slice(0, -1);
              }
              const splits = url.split("/");
              if (splits.length >= 2) {
                const id = parseInt(splits[splits.length - 1], 10);
                if (id) {
                  onScan(id);
                }
              }
            } else {
              console.log("invalid qr data");
            }
          }
        }}
        ViewFinder={() =>
          <div style={{
            position: 'absolute',
            zIndex: '1',
            top: 0,
            left: 0,
            right: 0,
            margin: '50px',
            aspectRatio: '1',
            boxShadow: '0px 0px 50px 120px rgba(0, 0, 0, 0.8)',
            border: '1px solid #ffffff',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
          />
        }
      />
    </Suspense>

  </div>
);


export default QR;

QR.propTypes = {
  onScan: PropTypes.func.isRequired,
};

// basic url check, not 100% foolproof
function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

function isCurrentSite(string) {
  if (string && isValidHttpUrl(string)) {
    // match current domain (localhost etc.), with optional /oorlogspad
    const regexPattern = `^(http(s)?://)?(www\\.)?${window.location.host}(\\/oorlogspad)?\\/qr\\/(\\d+)(\\/)?$`;
    const regex = new RegExp(regexPattern);
    return string.match(regex);
  } else {
    return false
  }
}

function isDomainMatch(string) {
  // match oorlogspad.nl and opoorlogspad.nl domains
  return string && isValidHttpUrl(string) && string.match(/^(http(s)?:\/\/)?(www\.)?(op)?oorlogspad\.nl\/qr\/(\d+)(\/)?$/);
}

