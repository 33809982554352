
// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import { useState } from "react";
import { Puzzle } from '../../../components/slide-puzzle';

import { AlertDialog } from '../../../components/dialog';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

export default function ContentPuzzle(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle } = content;

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog

  const completionText = 'Je hebt de puzzel opgelost. Goed gedaan!';

  function onPuzzleComplete() {
    setCompleteState(true);
    SetCompleteDialogState(true);
  }

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ padding: '16px !important', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'clamp(248px, 248px + 20vw, 600px)', width: 'clamp(248px, 248px + 20vw, 600px)', maxWidth: '100%' }}>
            <Puzzle imageUrl={formatUrl(url)} onComplete={() => onPuzzleComplete()} swapMode />
          </Container>
          <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "text"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar keuzemenu" : (isComplete ? "Verder" : "Overslaan")}</Button>
          </ButtonsContainer>
        </Stack>
      </Box>

      {/* correct dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}
