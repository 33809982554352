import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.77 156.77">
        <path fill="#f0c18c" d="m126.29,126.29l-8.3-13.83c6.28-7.29,10.58-16.32,12.07-26.28l26.71-7.79-26.71-7.79c-1.49-9.96-5.8-18.99-12.07-26.28l8.3-13.83-13.83,8.3c-7.29-6.28-16.32-10.58-26.28-12.07L78.39,0l-7.79,26.71c-9.96,1.49-18.99,5.8-26.28,12.07l-13.83-8.3,8.3,13.83c-6.28,7.29-10.58,16.32-12.07,26.28L0,78.39l26.71,7.79c1.49,9.96,5.8,18.99,12.07,26.28l-8.3,13.83,13.83-8.3c7.29,6.28,16.32,10.58,26.28,12.07l7.79,26.71,7.79-26.71c9.96-1.49,18.99-5.8,26.28-12.07l13.83,8.3Z"/>
        <path fill="#282828" d="m78.39,43.23c19.39,0,35.16,15.77,35.16,35.16s-15.77,35.16-35.16,35.16-35.16-15.77-35.16-35.16,15.77-35.16,35.16-35.16m0-8.71c-24.23,0-43.87,19.64-43.87,43.87s19.64,43.87,43.87,43.87,43.87-19.64,43.87-43.87-19.64-43.87-43.87-43.87h0Z"/>
        <polygon fill="#8c1d18" points="78.39 50.01 61.14 100.6 78.39 88.65 95.63 100.6 78.39 50.01"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.displayName = "Logo";

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
