import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import HomePageLayout from './layouts/home';
import ContentPageLayout from './layouts/content';
import OtherPageLayout from './layouts/other';

// pages
import RoutePage from './pages/RoutePage';
import Page404 from './pages/Page404';
import RouteOverviewPage from './pages/RouteOverviewPage';
import ContentPage from './pages/ContentPage';
import PermissionsPage from './pages/PermissionsPage';
import QRPage from './pages/QRPage';
import HoeWerktHet from './pages/HoeWerktHet';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <HomePageLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <RouteOverviewPage /> },
        { path: 'route/:routeId', element: <RoutePage /> },
        { path: 'qr/:routeId/', element: <RoutePage /> },
        { path: 'hoe-werkt-het/:routeId', element: <HoeWerktHet /> },
        { path: 'hoe-werkt-het/', element: <HoeWerktHet /> },
      ],
    },
    {
      element: <OtherPageLayout />,
      children: [
        { path: 'settings/permissions', element: <PermissionsPage /> },
        { path: 'qr', element: <QRPage /> },
        { path: '404', element: <Page404 /> },
      ],
    },
    {
      element: <ContentPageLayout />,
      children: [
        { path: 'route/:routeId/content', element: <ContentPage /> },
        { path: 'route/:routeId/content/:contentId', element: <ContentPage /> },
        { path: '404', element: <Page404 /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
