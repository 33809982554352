// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Routes',
    path: '/home',
    icon: <Iconify icon="ic:outline-place" width={22} height={22} />,
    children: [],
  },
  {
    title: 'Hoe werkt het?',
    path: '/hoe-werkt-het',
    icon: <Iconify icon="ic:outline-info" width={22} height={22} />,
    children: [],
  },
  {
    title: 'Instellingen',
    path: '/settings',
    icon: <Iconify icon="ic:outline-settings" width={22} height={22} />,
    children: [{
      title: 'Toestemmingen',
      path: '/settings/permissions',
      icon: <Iconify icon="ic:outline-settings" width={22} height={22} />,
    }]
  },
];

export default navConfig;
