
// @mui
import { useState, useImperativeHandle, useEffect, useRef } from 'react'
import { Box, Container, Button, Stack, IconButton } from '@mui/material';
// import useMediaQuery from '@mui/material/useMediaQuery';


// custom dialog
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { ModelViewer } from '../../../components/modelviewer';
import Iconify from '../../../components/iconify';
import { formatUrl } from '../../../utils/formatUrl';


import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { SupportsWebGL } from '../../../utils/supportsWebgl';


const useWebgl = SupportsWebGL();

// ----------------------------------------------------------------------

export default function ContentModel(content) {

    // const isCompactHeight = useMediaQuery('(min-width:1200px)');

    const { title, text, model, iosModel, poster, onComplete, fromMulti, allowPan, multiTitle, contentRef } = content;

    const iosMod = iosModel ? formatUrl(iosModel) : undefined;
    const [started, setStarted] = useState(false);
    const [startedOnce, setStartedOnce] = useState(false);
    const [arInfoDialogState, setArInfoDialogState] = useState(false);

    const arHelpText = "Hier komt een handige instructie over AR.";

    const touchRef = useRef(null);

    // block mobile (safari) back drag gesture by intercepting touch on the background
    useEffect(() => {
        const handleTouch = (e) => {
            if (e.changedTouches && e.changedTouches.length > 0) {
                const touch = e.changedTouches[0];
                if (touch && (touch.pageX || touch.pageY)) {
                    // dont block the top x pixels to not block any buttons
                    if (e.pageY && e.pageY < 0.15 * window.innerHeight) return; // safari only
                    if (touch.pageY < 0.15 * window.innerHeight) return;

                    // prevent swipe to navigate gesture
                    e.preventDefault();
                }
            }
        }

        const element = touchRef.current;
        if (started && element) {
            element?.addEventListener('touchstart', handleTouch);
        }

        return () => {
            element?.removeEventListener('touchstart', handleTouch);
        };
    }, [started]);


    // ContentPage back button intercept, return true if we already handled something.
    useImperativeHandle(contentRef, () => ({
        requestBack() {
            if (started) {
                setStarted(false);
                return true;
            }

            return false;
        }

    }), [started])

    return (<>

        {(!started || !useWebgl) ?
            <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px', position: 'relative' }}>

                <Stack sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>

                    <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Stack alignItems='center' spacing={2}>
                            {fromMulti ?
                                <Overline>
                                    {multiTitle}
                                </Overline>
                                : null}
                            <Title variant="h1">
                                {title}
                            </Title>
                            <SubTitle variant="body1">
                                {text}
                            </SubTitle>
                        </Stack>
                    </Container>

                    <Container sx={{ maxHeight: '40vh', pb: 2, flex: '3', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <img style={{ maxHeight: '100%' }} src={formatUrl(poster)} alt="artefact" className='unclickable unselectable' />
                    </Container>


                    <ButtonsContainer>
                        {useWebgl ?
                            <Button sx={{ mb: '12px' }} variant={startedOnce ? "text" : "contained"} onClick={() => { setStarted(true); setStartedOnce(true); }}> {startedOnce ? 'Opnieuw bekijken' : 'Bekijk in 3D'}</Button>
                            : null
                        }
                        <Button sx={{ mb: '0px' }} variant={(startedOnce || !useWebgl) ? "contained" : "text"} onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : ((startedOnce || !useWebgl) ? "Verder" : "Overslaan")}</Button>
                    </ButtonsContainer>
                </Stack>
            </Box>
            :
            <Box ref={touchRef} sx={{
                display: 'flex', position: 'fixed', height: '100vh', width: '100vw', p: '0px !important', zIndex: '10000', alignItems: 'center', inset: '0 0 0 0', backgroundImage: `url('${process.env.PUBLIC_URL}/assets/algemeen/images/background.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center'
            }}>

                <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={2} textAlign="left" alignItems='center' >
                    <ModelViewer
                        modelSrc={formatUrl(model)}
                        iosModelSrc={iosMod} posterSrc={formatUrl(poster)}
                        allowPan={allowPan ?? false} >
                        <>
                            <IconButton id="ar-info-button" sx={{ position: 'absolute', width: '56px', p: '16px', right: '16px', top: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { setArInfoDialogState(true) }} aria-label="info" component="label">
                                <Iconify icon="mdi:help-circle-outline" width='100%' height='100%' />
                            </IconButton>

                            { /* buttons needs to be a child with slot exit-webxr-ar-button */}
                            <IconButton slot="exit-webxr-ar-button" id="ar-close-button" sx={{ position: 'absolute', width: '56px', p: '16px', left: '16px', top: '16px', textTransform: 'none', color: '#D5D5D5' }} aria-label="close" component="label">
                                <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                            </IconButton>

                            { /* buttons needs to be a child with slot ar-button */}
                            <IconButton slot="ar-button" id="ar-button" sx={{ backgroundColor: 'primary.main', color: 'background.paper', top: '16px' }}>
                                <Iconify icon="mdi:cube-scan" width='100%' height='100%' />
                            </IconButton>

                            { /* custom dialog as modal dialog doesn't work over fullscreen ar */}
                            <Container onClick={() => setArInfoDialogState(false)} sx={{ zIndex: '99', display: arInfoDialogState ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '0px', left: '0px', height: '100vh', width: '100vw', backgroundColor: '#00000099' }}>

                                <Container sx={{
                                    padding: '0px',
                                    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
                                    borderRadius: '28px',
                                    backgroundColor: (theme) => theme.palette.background.appbar,
                                    minWidth: '240px'
                                }}>
                                    <DialogTitle id="alert-dialog-title" sx={{ padding: '24px 24px 16px 24px' }}>
                                        {title}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description" variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                                            {arHelpText}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ padding: '0px 16px 8px 16px' }}>
                                        <Button sx={{ textTransform: 'none' }} onClick={() => { }} autoFocus>Sluiten</Button>

                                    </DialogActions>


                                </Container>
                            </Container>
                        </>
                    </ModelViewer>




                </Stack>
                {
                    /* back button */
                    <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '0', left: '0', width: 'auto' }}>
                        <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { setStarted(false); }} aria-label="back" component="label">
                            <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                        </IconButton>
                    </Container>
                }

            </Box >
        }
    </>
    );
}

