import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import PropTypes from 'prop-types';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
Transition.displayName = "alertDialog";

AlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonFunc: PropTypes.func,
  secondaryButtonFunc: PropTypes.func,
};

export default function AlertDialog(props) {
  const { open, handleClose, title, content, primaryButtonText, secondaryButtonText, primaryButtonFunc, secondaryButtonFunc } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      componentsProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.60)" } } }}
      PaperProps={{ sx: { boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)', borderRadius: '28px', backgroundColor: 'background.appbar', minWidth: '240px' } }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ padding: '24px 24px 16px 24px' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant='body2' style={{whiteSpace: 'pre-wrap'}}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '0px 16px 8px 16px' }}>
        {primaryButtonText ? <Button sx={{ textTransform: 'none' }} onClick={() => { handleClose(); primaryButtonFunc(); }} autoFocus>{primaryButtonText}</Button> : null}
        {secondaryButtonText ? <Button sx={{ textTransform: 'none' }} onClick={() => { handleClose(); secondaryButtonFunc(); }} autoFocus>{secondaryButtonText}</Button> : null}
      </DialogActions>
    </Dialog>
  );
}