// @mui
import { useState, useRef, useImperativeHandle, useEffect } from "react";
import { Box, Container, Stack, IconButton } from '@mui/material';
import Lottie from "lottie-react";
import Button from '@mui/material/Button';
import ReactCompareImage from 'react-compare-image';
import { isSafari, isIOS } from "react-device-detect";
import Iconify from '../../../components/iconify';
import { AlertDialog } from '../../../components/dialog';
import { OverlayCam } from '../../../components/camera';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import animation from '../../../components/camera/overlayCamera/match-perspective.json';
import { RequestCamera } from "../../../utils/permission";
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

export default function ContentBeforeAfter(content) {
  const [started, setStarted] = useState(false);
  const [startedOnce, setStartedOnce] = useState(false);
  const { url, title, text, afterImage, outlineImage, onComplete, fromMulti, multiTitle, contentRef } = content;
  const [{ leftSrc, foundView, showButton }, setView] = useState({ leftSrc: undefined, foundView: false, showButton: true });

  const [infoDialogState, setInfoDialogState] = useState(false);
  const [cameraDialogState, setCameraDialogState] = useState(false);

  const cameraRef = useRef({});

  const helpText = "Probeer het perspectief van het camerabeeld en de illustratie te matchen.\n\nKlik op 'gevonden' als je denkt dat het klopt.\nZo krijg je een unieke blik op het verleden.";


  const touchRef = useRef(null);

  // block mobile (safari) back drag gesture by intercepting touch on the background
  useEffect(() => {
    const handleTouch = (e) => {
      if (e.changedTouches && e.changedTouches.length > 0) {
        const touch = e.changedTouches[0];
        if (touch && (touch.pageX || touch.pageY)) {
          // dont block the top x pixels to not block any buttons
          if (e.pageY && (e.pageY < 0.2 * window.innerHeight || e.pageY > 0.8 * window.innerHeight)) return; // safari only
          if (touch.pageY < 0.2 * window.innerHeight || touch.pageY > 0.8 * window.innerHeight) return;

          // prevent swipe to navigate gesture
          e.preventDefault();
        }
      }
    }

    const element = touchRef.current;
    if (started && element) {
      element?.addEventListener('touchstart', handleTouch);
    }

    return () => {
      element?.removeEventListener('touchstart', handleTouch);
    };
  }, [started]);



  function start() {
    // check if camera is allowed before opening the camera feed.
    RequestCamera(
      () => {
        // camera allowed
        setStarted(true);
        setStartedOnce(true);
      },
      () => {
        // denied
        setCameraDialogState(true);
      },
      () => {
        // unsupported, failed
        setCameraDialogState(true);
      }
    );

  }

  // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if (started) {
        setStarted(false);
        return true;
      }

      return false;
    }

  }), [started])


  return (<>
    {!started ?
      // camera not started
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ flexGrow: '1', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            <Box sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)' }}>
              <Lottie animationData={animation} />
            </Box>
          </Container>

          <ButtonsContainer>
            <Button sx={{ mb: '12px' }} variant={startedOnce ? "text" : "contained"} onClick={() => { start(); }}> {startedOnce ? 'Opnieuw starten' : 'Start'}</Button>
            <Button sx={{ mb: '0px' }} variant={startedOnce ? "contained" : "text"} onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : (startedOnce ? "Verder" : "Overslaan")}</Button>
          </ButtonsContainer>
        </Stack>
      </Box> :

      // camera active
      <Box ref={touchRef} sx={{
        position: 'fixed', height: '100%', width: '100vw', top: '0'
      }}>
        <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative', p: '0px !important', backgroundColor: 'black', height: '100%' }}>

          {
            foundView ?
              <Box sx={{ maxWidth: '100%', aspectRatio: '16 / 9', overflow: 'hidden', display: 'flex', justifyContent: 'center', }}>
                <Box sx={{ width: (isSafari || isIOS) ? '100%' : null, aspectRatio: '16 / 9', overflow: 'hidden', display: 'flex', justifyContent: 'center', flexDirection: 'column', maxHeight: '100%' }}>
                  <ReactCompareImage style={{}} leftImage={leftSrc ?? ''} rightImage={formatUrl(afterImage)} aspectRatio={'taller'} leftImageCss={{ objectFit: 'cover' }} rightImageCss={{ objectFit: 'cover' }} />
                </Box>
              </Box>
              :
              <Box sx={{ maxWidth: '100%', aspectRatio: '16 / 9', overflow: 'hidden' }}>
                <Box sx={{ aspectRatio: '16 / 9', overflow: 'hidden', display: 'flex', justifyContent: 'center', flexDirection: 'column', maxHeight: '100%' }}>
                  <OverlayCam myRef={cameraRef} style={{}} overlaySrc={formatUrl(outlineImage)} />
                </Box>
              </Box>
          }

          {!foundView && showButton ?
            <ButtonsContainer sx={{ position: 'absolute', bottom: '0' }}>
              <Button sx={{ mb: '16px' }} variant="contained" onClick={() => {
                if (!foundView) {
                  setView({ leftSrc: url, foundView: false, showButton: false });
                  cameraRef.current.takePhoto().then((url) => {
                    if (url) {
                      setView({ leftSrc: url, foundView: true, showButton: true });
                    }
                  })
                }
              }}>Gevonden</Button>
            </ButtonsContainer> : <ButtonsContainer sx={{ position: 'absolute', bottom: '0' }}>
              <Button sx={{ mb: '16px' }} variant="contained" onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : "Verder"}</Button>
            </ButtonsContainer>}
          { /* back button */}
          <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '0', left: '0', width: '100%' }}>
            <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { back(); }} aria-label="back" component="label">
              <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
            </IconButton>
            <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { setInfoDialogState(true); }} aria-label="help" component="label">
              <Iconify icon="mdi:help-circle-outline" width='100%' height='100%' />
            </IconButton>
          </Container>
        </Container>
      </Box>}
    <AlertDialog open={infoDialogState} handleClose={() => setInfoDialogState(false)} title="Instructies"
      content={helpText}
      secondaryButtonText="Ik begrijp het"
      secondaryButtonFunc={() => { setInfoDialogState(false) }}
    />
    {/* camera permission dialog */}
    <AlertDialog open={cameraDialogState} handleClose={() => setCameraDialogState(false)} title="Geen cameratoestemming"
      content="Deze ervaring heeft toegang tot je camera nodig. Verander deze toestemming voor de oorlogspad website in je browser."
      primaryButtonText=""
      secondaryButtonText="Terug"
      secondaryButtonFunc={() => { setCameraDialogState(false) }}
    />
  </>
  );

  function back() {
    if (!foundView) {
      setView({ leftSrc, foundView: false, showButton: true });
      setStarted(false)
    } else {
      setView({ leftSrc, foundView: false, showButton: true });
    }

  }
}


