
// @mui
import { Box, Stack, Container, Button, IconButton } from '@mui/material';
import { useState, useImperativeHandle } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import Lottie from "lottie-react";
import animation from '../../../components/panorama/spherical-view.json';
import Iconify from '../../../components/iconify';
import { Panorama } from '../../../components/panorama';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

export default function ContentPanorama(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, contentRef, infoSpots } = content;

  const [started, setStarted] = useState(false);
  const [startedOnce, setStartedOnce] = useState(false);

  const isCompactHeight = useMediaQuery('(min-width:1200px)');

  // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if (started) {
        setStarted(false);
        return true;
      }

      return false;
    }

  }), [started])

  return (
    <>
      {!started ?
        // camera not started
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
          <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
            <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <Stack alignItems='center' spacing={2}>
                {fromMulti ?
                  <Overline>
                    {multiTitle}
                  </Overline>
                  : null}
                <Title variant="h1">
                  {title}
                </Title>
                <SubTitle variant="body1">
                  {text}
                </SubTitle>
              </Stack>
            </Container>
            <Container sx={{ flexGrow: '1', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <Box sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)' }}>
                <Lottie animationData={animation} />
              </Box>
            </Container>

            <ButtonsContainer>
              <Button sx={{ mb: '12px' }} variant={startedOnce ? "text" : "contained"} onClick={() => { setStarted(true); setStartedOnce(true); }}> {startedOnce ? 'Opnieuw starten' : 'Start'}</Button>
              <Button sx={{ mb: '0px' }} variant={startedOnce ? "contained" : "text"} onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : (startedOnce ? "Verder" : "Overslaan")}</Button><></>
            </ButtonsContainer>
          </Stack>
        </Box>

        :

        <Box sx={{ width: '100%', height: '100%' }}>
          <Stack sx={{ width: '100%', height: '100%', position: isCompactHeight ? 'relative' : 'fixed', top: 0 }} textAlign="left">
            <Panorama imageSrc={formatUrl(url)} infoSpots={infoSpots} />
            <ButtonsContainer sx={{ position: 'absolute', bottom: '0' }}>
              <Button sx={{ mb: '24px' }} variant="contained" onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : "Verder"}</Button>
            </ButtonsContainer>
            {
              /* back button */
              !isCompactHeight ?
                <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '0', left: '0', width: 'auto' }}>
                  <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { setStarted(false); }} aria-label="back" component="label">
                    <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                  </IconButton>
                </Container> : <></>
            }
          </Stack>
        </Box>
      }
    </>
  );
}
