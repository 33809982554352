import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
// import { bgBlur } from '../../../utils/cssStyles';
// mock
import settings from '../../../_mock/settings';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
//


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  
  const isDesktop = useResponsive('up', 'lg');
  
  const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    backgroundColor: isDesktop ? 'transparent' : theme.palette.background.appbar,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
  }));
  
  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: HEADER_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }));
 
  return (
    <StyledRoot>
      <StyledToolbar>
          
        { isDesktop ? null

        :

        <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
          <Logo />
            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', fontSize: '1.25rem !important' }}>
              {settings.displayName}
              </Typography>
            </Box>
        </Box>  
      }


       
        <IconButton
          onClick={onOpenNav}
          sx={{
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      </StyledToolbar>
    </StyledRoot>
  );
}
