// @mui
import { useState, useRef, useImperativeHandle } from 'react';
import { Stack, Box, Typography, Button, Container, IconButton, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import Lottie from "lottie-react";
import { AlertDialog } from '../../../components/dialog';
import { SelfieCam } from '../../../components/camera';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import Iconify from '../../../components/iconify';
import animation from '../../../components/camera/selfieCamera/foto-maken.json';
import { RequestCamera } from '../../../utils/permission';
import { formatUrl } from "../../../utils/formatUrl";

// ----------------------------------------------------------------------

const CameraContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: 'black',
  height: '100%',
  width: '100%',

  '@media (orientation: landscape)': {
    flexDirection: 'row',
  },
  '@media (orientation: portrait)': {
    flexDirection: 'column',
  },

});

const CameraButtonDiv = styled('div')({
  flexDirection: 'column',
  display: 'flex',
  position: 'absolute',

  '@media (orientation: landscape)': {
    justifyContent: 'flex-end',
    right: '16px',
  },

  '@media (orientation: portrait)': {
    justifyContent: 'center',
    bottom: '16px'
  }

});



const FabNextButton = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '@media (orientation: portrait)': {
    display: 'none',
  }

});

const WideNextButton = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',

  '@media (orientation: landscape)': {
    display: 'none'
  },

});


// ----------------------------------------------------------------------



export default function ContentShare(content) {
  const { text, title, onComplete, fromMulti, multiTitle, contentRef } = content;
  const cameraRef = useRef({});
  const fileName = useRef(undefined);

  const saved = useRef(false);

  const [isPreview, setPreview] = useState(false);
  const [started, setStarted] = useState(false);
  const [startedOnce, setStartedOnce] = useState(false);

  const [confirmDialogState, setConfirmDialogState] = useState(false);
  const [cameraDialogState, setCameraDialogState] = useState(false);

  function start() {
    // check if camera is allowed before opening the camera feed.
    RequestCamera(
      () => {
        console.log("aap");
        // camera allowed
        setStarted(true);
        setStartedOnce(true);
      },
      () => {
        // denied
        setCameraDialogState(true);
      },
      () => {
        // unsupported, failed
        setCameraDialogState(true);
      }
    );

  }

  // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if (started) {
        setStarted(false);
        return true;
      }

      return false;
    }

  }), [started])

  return (
    <>
      {!started ?
        // camera not started
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
          <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={2} textAlign="left" >
            <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <Stack alignItems='center' spacing={2}>
                {fromMulti ?
                  <Overline>
                    {multiTitle}
                  </Overline>
                  : null}
                <Title variant="h1">
                  {title}
                </Title>
                <SubTitle variant="body1">
                  {text}
                </SubTitle>
              </Stack>
            </Container>
            <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>
              <Box sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)' }}>
                <Lottie animationData={animation} />
              </Box>
            </Container>

            <ButtonsContainer>
              <Button sx={{ mb: '12px' }} variant={startedOnce ? "text" : "contained"} onClick={() => { start(); }}> {startedOnce ? 'Opnieuw starten' : 'Start'}</Button>
              <Button sx={{ mb: '0px' }} variant={startedOnce ? "contained" : "text"} onClick={() => onComplete()}>{fromMulti ? "Terug naar keuzemenu" : "Overslaan"}</Button>
            </ButtonsContainer>
          </Stack>
        </Box>

        :

        <Box sx={{
          position: 'fixed', height: '100%', width: '100vw', top: '0'
        }}>
          <CameraContainer>
            <SelfieCam myRef={cameraRef} overlaySrc={formatUrl(`/assets/algemeen/illustrations/overlay.svg`)} />
            {!isPreview ?
              /* photo button */
              <CameraButtonDiv>
                <div style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>


                  <Fab sx={{ mb: '8px' }} color="primary" aria-label="camera" onClick={() => {
                    takePhoto();
                  }}>
                    <Iconify icon="material-symbols:photo-camera-outline" sx={{ color: 'background.paper' }} width='50%' height='50%' />
                  </Fab>
                  <Typography variant="body">
                    Foto maken
                  </Typography>
                </div>
              </CameraButtonDiv> :


              // next button
              <>
                <FabNextButton> {/* landscape only */}
                  <CameraButtonDiv>
                    <div style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
                      <Fab color="primary" aria-label="next" onClick={() => { next(); }}>
                        <Iconify icon="mdi:chevron-right" sx={{ color: 'background.paper' }} width='50%' height='50%' />
                      </Fab>
                      <Typography variant="body">
                        {fromMulti ? "Terug naar keuzemenu" : "Verder"}
                      </Typography>
                    </div>
                  </CameraButtonDiv>
                </FabNextButton>
                <WideNextButton> {/* portrait only */}
                  <ButtonsContainer sx={{ display: 'grid', position: 'absolute', bottom: '0' }}>
                    <Button sx={{ mb: '16px' }} variant="contained" onClick={() => { next(); }}> {fromMulti ? "Terug naar keuzemenu" : "Verder"}</Button>
                  </ButtonsContainer>


                </WideNextButton>
              </>
            }
            {  /* back button */}

            <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '0', left: '0', right: '0', width: '100%', maxWidth: '100% !important' }}>
              <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { back(); }} aria-label="back" component="label">
                <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
              </IconButton>
              {isPreview ?
                <Container sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0', padding: '0 !important', width: '100%' }}>
                  {navigator.canShare ?
                    <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { share(); }} aria-label="share" component="label">
                      <Iconify icon="material-symbols:share-outline" width='100%' height='100%' />
                    </IconButton> : <></>
                  }
                  <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { download(); }} aria-label="download" component="label">
                    <Iconify icon="material-symbols:download" width='100%' height='100%' />
                  </IconButton>
                </Container> : <></>}
            </Container>

          </CameraContainer>
        </Box>
      }

      {/* confirm dialog */}
      <AlertDialog open={confirmDialogState} handleClose={() => setConfirmDialogState(false)} title="Niet opgeslagen"
        content="Je hebt de foto niet gedownload of gedeeld. Wil je verder zonder de foto?"
        primaryButtonText="Verder" secondaryButtonText="Terug"
        primaryButtonFunc={() => { onComplete() }} secondaryButtonFunc={() => { }}
      />

      {/* camera permission dialog */}
      <AlertDialog open={cameraDialogState} handleClose={() => setCameraDialogState(false)} title="Geen cameratoestemming"
        content="Deze ervaring heeft toegang tot je camera nodig. Verander deze toestemming voor de oorlogspad website in je browser."
        primaryButtonText=""
        secondaryButtonText="Terug"
        secondaryButtonFunc={() => { setCameraDialogState(false) }}
      />
    </>

  );

  function next() {
    if (!saved.current) {
      setConfirmDialogState(true);
    }
    else {
      onComplete();
    }
  }

  function takePhoto() {
    if (!isPreview && cameraRef.current.isReady()) {
      cameraRef.current.pauseCameraStream();
      setPreview(true);
      fileName.current = getDateFilename();
    }
  }

  function back() {
    if (isPreview) {
      cameraRef.current.playCameraStream();
      setPreview(false);
    } else {
      setStarted(false);
    }
  }

  function getDateFilename() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const hh = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');
    const sec = String(today.getSeconds()).padStart(2, '0');

    return `oorlogspad-${yyyy + mm + dd + hh + min + sec}`;
  }

  async function download() {
    if (isPreview && fileName.current) {
      const blob = await cameraRef.current.getImageBlob();
      const URLObj = window.URL || window.webkitURL;
      const a = document.createElement("a");
      a.href = URLObj.createObjectURL(blob);
      a.download = fileName.current;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      saved.current = true;
    }
  }

  async function share() {
    if (isPreview && fileName.current && navigator.canShare) {
      const blob = await cameraRef.current.getImageBlob();
      const filesArray = [
        new File(
          [blob],
          `${fileName.current}.jpg`,
          {
            type: "image/jpeg",
            lastModified: new Date().getTime()
          }
        )
      ];
      const shareData = {
        files: filesArray,
      };
      navigator.share(shareData);
      saved.current = true;
    } else {
      console.log("Share not available");
      download();
    }
  }
}
