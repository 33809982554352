// ----------------------------------------------------------------------

// import { upperCase } from "lodash";

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Poppins, sans-serif'; // Google Font
const FONT_SECONDARY = 'Teko, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: { // Headline
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: 1,
    fontSize: pxToRem(45),
    ...responsiveFontSizes({ sm: 45, md: 57, lg: 65 }),
  },
  h2: { // Kompas afstand
    fontWeight: 500,
    lineHeight: 64 / 48,
    fontSize: pxToRem(33),
    ...responsiveFontSizes({ sm: 33, md: 37, lg: 41 }),
  },
  h3: { // Title L - Homepage card title, Routenaam in description
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ sm: 19, md: 19, lg: 19 }),
  },
  h4: { // Title M - Content multi card title
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(15),
    ...responsiveFontSizes({ sm: 15, md: 17, lg: 17 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: { // Main body text
    lineHeight: 1.667,
    fontSize: pxToRem(15),
    letterSpacing: 0.3,
  },
  body2: { // Routebeschrijving, Video button labels, search bar
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
    letterSpacing: 0.25,
  },
  body3: { // Timecode video/audio, plaatsnaam route card
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
    letterSpacing: 0.5,
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
    letterSpacing: 1,
    textTransform: 'unset',
  },
};

export default typography;
