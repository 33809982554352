import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Box, Button } from '@mui/material';

import { Link as RouterLink } from "react-router-dom";

// components
import { RouteSearch, RouteList, RouteFilterSidebar } from '../sections/@home/routes';

// mock
import { routes } from '../_mock/routes';

import './style.css';
import { FILTER_CATEGORY_OPTIONS } from '../sections/@home/routes/RouteFilterSidebar';

// ----------------------------------------------------------------------

export default function HomePage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [searchedRoute, setSearchedRoute] = useState(null);


  const [filterSelection, setFilterSelection] = useState(FILTER_CATEGORY_OPTIONS.map(() => true));

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };


  const toggleFilter = (index, state) => {
    setFilterSelection(filterSelection.map((option, i) => i === index ? state : option));

  };

  function onSearchSelect(route) {
    setSearchedRoute(route);
  }



  return (
    <>
      <Helmet>
        <title> Oorlogspad </title>
      </Helmet>
      <Box className='hero-section' sx={{ mb: 5 }}>
        <Container sx={{ height: '100%' }}>
          <Stack className='hero-text-wrapper'>
            <Typography variant="h1" sx={{ mb: 2, fontSize: 'clamp(2rem, 1rem + 9vw, 5rem) !important' }}>
              Daar gebeurd, waargebeurd.
            </Typography>
            <Typography variant="body" sx={{ mb: 4, maxWidth: '60ch' }}>
              Ga mee op Oorlogspad dwars door Gelderland en ontdek waargebeurde verhalen uit de Tweede Wereldoorlog.
            </Typography>
            <Button to="../hoe-werkt-het" component={RouterLink} variant="contained" sx={{ mr: 'auto', maxWidth: '480px !important', minWidth: '160px' }}>
              Hoe werkt het?
            </Button>
          </Stack>
        </Container>
      </Box>
      <Container>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 3 }}>
          <Stack className='searchbar' direction="row" flexShrink={0} sx={{ my: 1 }}>
            <RouteSearch routes={routes} onSelection={(val) => onSearchSelect(val)} />
            <RouteFilterSidebar
              selection={filterSelection}
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              onFilterChange={toggleFilter}
            />
          </Stack>
        </Stack>
        <RouteList routes={searchedRoute ? [searchedRoute] : routes.filter((route) => filterSelection[route.category])} />
      </Container>
    </>
  );
}
