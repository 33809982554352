import { styled } from '@mui/material/styles';

export const Canvas = styled('canvas')(({ aspect }) => ({
  objectFit: 'contain',



  '@media (orientation: landscape)': {
    maxWidth: '70%',
    maxHeight: '100%',
  },
  '@media (orientation: portrait)': {
    maxWidth: '100%',
    maxHeight: '70%',
  },
  aspectRatio: `${aspect} !important`,
}));


export const Video = styled('video')({
  position: 'absolute',

  '&::-webkit-media-controls-play-button': {
    display: 'none !important',
    webkitAppearance: 'none',
  }
});


