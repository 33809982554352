import { forwardRef } from "react";
import { ButtonBase, Typography, Box } from '@mui/material';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Iconify from "../iconify";
import { formatUrl } from "../../utils/formatUrl";

import { ContentIcon } from ".";

export const IconContainer = styled('div')({
  width: '40px',
  height: '40px',
  top: '16px',
  right: '16px',
  background: '#0B6E4F',
  borderRadius: '100%',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex'
});

const SelectionOverlay = styled('div')(({ theme }) => ({
  border: `4px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  position: 'absolute',
}));

// const CAROUSEL_ITEM_SIZE = 300;

const CarouselItem = forwardRef(({ ...props }, ref) => (
  <div style={{ flexDirection: 'column' }}>
    <ButtonBase onClick={() => { props.callback() }}>
      <div
        style={{
          // maxWidth: '70vw',
          // width: `${CAROUSEL_ITEM_SIZE}px`,
          // height: `${CAROUSEL_ITEM_SIZE}px`,
          background: 'linear-gradient(0deg, #1C1C1C 0%, #474747 100%)',
          backgroundSize: 'cover',
          borderRadius: '12px',
          overflow: 'hidden',
          height: 'clamp(172px, 1rem + 26vh, 392px)',
          width: 'clamp(172px, 1rem + 26vh, 392px)'
        }}
        ref={ref}
      >

        {props.content.thumbnail !== (undefined || "") ?
          <img className={"unclickable unselectable"} style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={formatUrl(props.content.thumbnail)} alt="img" />
          :
          <ContentIcon type={`${props.content.type}`} />
        }

        {props.completed ?
          <IconContainer>
            <Iconify sx={{ width: '24px', height: '24px', color: '#D2F2D0' }} icon='ic:baseline-check' />
          </IconContainer>
          : null}

      </div>

      {props.selected ? <>
        <SelectionOverlay />
        <Box sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '100%', width: '48px', height: '48px', backgroundColor: props.selected ? 'common.white' : '#1C1C1C80', ":hover": { background: props.selected ? '' : '#000' } }} aria-label="select content">
          <Iconify sx={{ width: '24px', height: '24px', color: 'primary.main' }} icon='ic:baseline-search' />
        </Box>
      </> : null}

    </ButtonBase>
    <Typography variant='h4' sx={{ textAlign: 'center', mt: 2, textOverflow: 'ellipsis', width: 'clamp(172px, 1rem + 26vh, 392px)', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.content.title}</Typography>
  </div>
));

CarouselItem.propTypes = {
  content: PropTypes.object,
  children: PropTypes.object,
  callback: PropTypes.func,
  completed: PropTypes.bool,
  selected: PropTypes.bool
}

CarouselItem.displayName = "carouselImage";

export default CarouselItem;