import { forwardRef } from "react";
import { ButtonBase, Box } from '@mui/material';

import PropTypes from 'prop-types';
import { formatUrl } from "../../utils/formatUrl";

// import useResponsive from '../../hooks/useResponsive';

import Iconify from "../iconify";

// const CAROUSEL_ITEM_SIZE = 300;

/*
const SelectionOverlay = styled('div')(({ theme }) => ({
  border: `4px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  position: 'absolute',
});
*/

const CarouselImage = forwardRef(({ ...props }, ref) => (
  <div style={{ flexDirection: 'column' }}>
    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', position: 'relative' }} onClick={() => { props.onClick(); }}>
      <div
        className={"unclickable unselectable"}
        style={{

          // maxHeight: '70vh',
          // width: `${CAROUSEL_ITEM_SIZE}px`,
          // height: `${CAROUSEL_ITEM_SIZE}px`,
          backgroundImage: `url(${formatUrl(props.image)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 'clamp(172px, 172px + 50vw, min(540px, 72vw))',
          width: 'clamp(172px, 172px + 50vw, min(540px, 72vw))',
          Width: `${props.multiple ? 'min(540px, 72vw)' : '100vw'}`,
          Height: `${props.multiple ? 'min(540px, 72vw)' : '100vw'}`,
        }}
        ref={ref}
      />

      <Box sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '100%', width: '48px', height: '48px', backgroundColor: 'common.white', transition: '0.25s' }} aria-label="select image">
        <Iconify sx={{ width: '24px', height: '24px', color: 'primary.main' }} icon='ic:baseline-search' />
      </Box>
    </ButtonBase>
  </div>
));

CarouselImage.propTypes = {
  image: PropTypes.string,
  selected: PropTypes.bool,
  multiple: PropTypes.bool,
  onClick: PropTypes.func,
}

CarouselImage.displayName = "carouselImage";

export default CarouselImage;

