import { React, useState, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import * as consts from './key';
import mapStyle from './style.json';
import { formatUrl } from "../../utils/formatUrl";

import MarkerImg from './images/marker.png';
import LocationImg from './images/location.png';

const containerStyle = {
  position: 'relative',
  height: '100%',
  width: `100%`,
};


const mapOptions = {
  fullscreenControl: false,
  disableDefaultUI: true,
  styles: mapStyle,
  gestureHandling: "none",
};



function Map({ targetPosition, currentPosition, boundsPositions }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: consts.key,
    language: "nl",
    region: "nl",
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null)


  const onLoad = useCallback((map) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < boundsPositions.length; i += 1) {
      bounds.extend(boundsPositions[i]);
    }
    map.fitBounds(bounds);

    setMap(map)
  }, [boundsPositions])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={targetPosition}
      zoom={13}
      onLoad={onLoad}
      options={mapOptions}
      onUnmount={onUnmount}
      clickableIcons={false}
    >
      {currentPosition ?
        <Marker key="position_marker"
          position={currentPosition}
          icon={{
            url: formatUrl(LocationImg),
            anchor: new window.google.maps.Point(5, 5),
            scaledSize: new window.google.maps.Size(10, 10)
          }}
        />
        : null}
      <Marker key="target_marker"
        position={targetPosition}
        icon={{
          url: formatUrl(MarkerImg),
          anchor: new window.google.maps.Point(16, 40),
          scaledSize: new window.google.maps.Size(32, 40)
        }}
      />
    </GoogleMap>
  ) : <></>
}

Map.propTypes = {
  targetPosition: PropTypes.object,
  currentPosition: PropTypes.object,
  boundsPositions: PropTypes.array,
};

export default Map;

