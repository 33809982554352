import React, { useState, useRef, useCallback, useMemo, useEffect, useImperativeHandle } from "react";
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import PropTypes from 'prop-types';


import {
  Video,
  Canvas,
} from "./styles";
import { Flash } from "../flash";




export default function Camera({ overlaySrc, myRef }) {
  const canvasRef = useRef();
  const videoRef = useRef();
  const cameraRef = useRef();
  const animFrameId = useRef(null);


  const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight);
  const [isFlashing, setIsFlashing] = useState(false);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const overlayImage = useMemo(() => new Image, []);

  const aspect = 0.75; // 4:3 vertical

  useEffect(() => {
    function onResize() {
      if (window.innerWidth > window.innerHeight !== isLandscape) {
        console.log("reset size");
        setTimeout(() => {
          setLandscape(window.innerWidth > window.innerHeight);
        }, 25);
      }
    }
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [isLandscape]);


  // load image to overlay on camera
  useEffect(() => {
    if (overlaySrc) {
      overlayImage.onload = () => {
        console.log("loaded");
        setImageLoaded(true);
      };
      // trigger img load
      overlayImage.src = overlaySrc;
      console.log("loading overlay");
    }
  }, [overlayImage, overlaySrc]);

  // init camera
  useEffect(() => {
    cameraRef.current = new CameraPhoto(videoRef.current);
    cameraRef.current.startCamera(FACING_MODES.USER, { width: 1920, height: 1080 })
      .then(() => {
        console.log('camera started !');
      })
      .catch((error) => {
        console.error('Camera not started!', error);
      });
  }, []);

  // dispose
  useEffect(() => () => {
    console.log("camera cleanup");
    cancelAnimationFrame(animFrameId.current);
    stopCamera();
    videoRef.current = null;
    cameraRef.current = null;
  }, [])


  function stopCamera() {
    if (cameraRef.current) {
      cameraRef.current.stopCamera()
        .then(() => {
          console.log('Camera stopped!');
        })
        .catch(() => {
          console.log('No camera to stop!');
        });
    }
  }




  function handleCanPlay() {
    //   calculateCameraRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    playCameraStream();
  }

  function handleFrame() {
    if (!canvasRef || !canvasRef.current || !videoRef.current || !cameraRef.current || !isVideoPlaying) {
      return;
    }
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // don't rely on canvasRef.current.width, as that isnt always up to date with screen rotations
    const internalWidth = getVideoWidth();
    const internalHeight = internalWidth / aspect;
    const { videoHeight, videoWidth } = videoRef.current;


    const offsetX = videoWidth > internalWidth ? Math.round((videoWidth - internalWidth) / 2) : 0;
    const offsetY = videoHeight > internalHeight ? Math.round((videoHeight - internalHeight) / 2) : 0;

    // mirror canvas for selfie camera
    context.translate(internalWidth, 0);
    context.scale(-1, 1);

    // camera feed
    context.drawImage(
      videoRef.current,
      offsetX,
      offsetY,
      internalWidth, // clipping size
      internalHeight,
      0,
      0,
      internalWidth, // full render size
      internalHeight,
    );

    // unmirror for overlay
    context.scale(-1, 1);
    context.translate(-internalWidth, 0);

    // overlay image
    if (isImageLoaded) {
      context.drawImage(
        overlayImage,
        /* 0,
           0,
          internalWidth,
          internalHeight 
          */
        // overdraw a pixel (scaled by aspect) to avoid a rounding isue
        -1,
        - 1 / aspect,
        internalWidth + 2,
        internalHeight + 2 * (1 / aspect),
      );
    }

    if (isVideoPlaying) {
      animFrameId.current = requestAnimationFrame(handleFrame);
    }
  }


  const playCameraStream = useCallback(() => {
    videoRef.current.play()
    setIsVideoPlaying(true);
  }, [videoRef, setIsVideoPlaying]);


  const pauseCameraStream = useCallback(() => {
    videoRef.current.pause();
    setIsVideoPlaying(false);
    setIsFlashing(true);
  }, [videoRef, setIsVideoPlaying]);

  function getVideoWidth() {
    if (videoRef.current) {
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;

      if (width > height) {
        if (width / aspect > height) {
          return Math.round(height * aspect);
        } else {
          return width;
        }
      } else if (height >= width) {
        if (height / aspect > width) {
          return width;
        } else {
          return width;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getImageBlob = useCallback(async () => {
    const blob = await new Promise(resolve => canvasRef.current.toBlob(resolve, "image/jpeg", 1));
    return blob;
  }, [canvasRef]);


  const isReady = useCallback(() => isVideoPlaying && isImageLoaded, [isImageLoaded, isVideoPlaying]);

  useImperativeHandle(myRef, () => ({
    pauseCameraStream, playCameraStream, getImageBlob, isReady
  }), [pauseCameraStream, playCameraStream, getImageBlob, isReady])



  // start anim loop to update camera in canvas 
  if (animFrameId.current) {
    cancelAnimationFrame(animFrameId.current);
  }
  animFrameId.current = requestAnimationFrame(handleFrame);


  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: "100%", objectFit: 'contain' }}>
        <Canvas
          ref={canvasRef}
          // internal resolution
          aspect={aspect}
          width={getVideoWidth()}
          height={Math.round(getVideoWidth() / aspect)}
        />
        <Flash
          flash={isFlashing}
          onAnimationEnd={() => setIsFlashing(false)}
        />
        {
          // source for canvas, but not visible
          <Video
            ref={videoRef}
            hidden
            onCanPlay={() => handleCanPlay()}
            autoPlay
            width="100%"
            height="100%"
            playsInline
            muted
          />
        }
      </div>
    </>
  );



}

Camera.propTypes = {
  overlaySrc: PropTypes.string.isRequired,
  myRef: PropTypes.any.isRequired,
};
