// @mui
import { Box, Stack, Container, Button } from '@mui/material';
// import { OrientationOverlay } from '../../../components/orientation-overlay';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';

// ----------------------------------------------------------------------

export default function ContentText(content) {
  const { title, text, onComplete, fromMulti, multiTitle } = content;
  return (
    <>
      { /* <OrientationOverlay /> */}

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { onComplete() }}>{fromMulti ? "Terug naar keuzemenu" : "Verder"}</Button>
          </ButtonsContainer>
        </Stack>
      </Box>
    </>
  );
}
