import { useRef, forwardRef, useState } from "react";

import { ButtonBase, Stack, Box, Typography, Button, Container } from '@mui/material';

import PropTypes from 'prop-types';

import {
  SnapList,
  SnapItem,
  useScroll,
  useDragToScroll
} from 'react-snaplist-carousel';

import { styled } from '@mui/material/styles';
import { isMobile } from "react-device-detect";
import { AlertDialog } from '../../../components/dialog';

import { CarouselNavigationButton } from '../../../components/carousel';

import Iconify from "../../../components/iconify";

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { formatUrl } from "../../../utils/formatUrl";

export default function ContentPictureFinder(content) {
  const snapList = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [wrongDialogState, SetWrongDialogState] = useState(false); // wrong answer Dialog

  const goToSnapItem = useScroll({ ref: snapList });
  useDragToScroll({ ref: snapList });

  const completionText = 'Je hebt de juiste afbeelding gevonden. Goed bezig!';

  // const CAROUSEL_ITEM_SIZE = 300;

  const { title, text, items, correctItem, onComplete, fromMulti, multiTitle } = content;

  const SelectionOverlay = styled('div')(({ theme }) => ({
    border: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '12px',
    height: '100%',
    width: '100%',
    position: 'absolute',
  }));

  const CarouselImage = forwardRef(({ children, ...props }, ref) => (
    <div style={{ flexDirection: 'column' }}>
      <ButtonBase onClick={() => clickImage(props.index)}>
        <div
          style={{
            // maxWidth: '70vw',
            // width: `${CAROUSEL_ITEM_SIZE}px`,
            // height: `${CAROUSEL_ITEM_SIZE}px`,
            background: `url('${formatUrl(props.item.image)}')`,
            backgroundSize: 'cover',
            borderRadius: '12px',
            height: 'clamp(172px, 1rem + 26vh, 392px)',
            width: 'clamp(172px, 1rem + 26vh, 392px)'
          }}
          ref={ref}
        >
          {children}
        </div>

        {props.index === selectedItem ? <SelectionOverlay /> : null}
      </ButtonBase>
      <Typography variant='h4' sx={{ textAlign: 'center', mt: 2, textOverflow: 'ellipsis', width: 'clamp(172px, 1rem + 26vh, 392px)', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.item.name}</Typography>
    </div>
  ));

  CarouselImage.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.object
  }

  CarouselImage.displayName = "carouselImage";

  const imageButtons = getImages(items);

  function clickImage(i) {
    goToSnapItem(i);
    setSelectedItem(i);
  }

  function isGameComplete() {
    if (!isComplete && (correctItem === selectedItem)) {
      setCompleteState(true);

      if (!completeDialogState) {
        SetCompleteDialogState(true);
      }
    }
    else {
      SetWrongDialogState(true);
    }
  }

  function nextImage() {
    clickImage(selectedItem + 1);
  }

  function previousImage() {
    clickImage(selectedItem - 1);
  }

  function getImages(items) {
    const carouselItems = [];
    for (let i = 0; i < items.length; i += 1) {
      carouselItems.push(
        <SnapItem key={i} margin={{ left: '15px', right: '15px' }} snapAlign="center">
          <CarouselImage item={items[i]} index={i} />
        </SnapItem>
      );
    }
    return carouselItems;
  }

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
        <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              {fromMulti ?
                <Overline>
                  {multiTitle}
                </Overline>
                : null}
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>
            <SnapList
              ref={snapList}
              tabIndex={0} // so it can receive focus and can be scrolled with keyboard
              role="region" // context for screen readers
              aria-label="carousel" // for screen readers to read out loud on focus
              className="carousel"
              style={{ flexGrow: '3', scrollPadding: '0px', padding: '0px 12px', WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)', maskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)' }}
            >
              {imageButtons}
            </SnapList>

            {!isMobile &&
              // hide scroll buttons if mobile touchscreen
              <>
                {selectedItem !== 0 &&
                  <CarouselNavigationButton onClick={() => previousImage()} sx={{ left: '8px' }} aria-label="previous content">
                    <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-before' />
                  </CarouselNavigationButton>
                }

                {selectedItem < items.length - 1 &&
                  <CarouselNavigationButton onClick={() => nextImage()} sx={{ right: '8px' }} aria-label="next content">
                    <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-next' />
                  </CarouselNavigationButton>
                }
              </>
            }
          </Container>
          <ButtonsContainer>
            <Button disabled={(selectedItem === null) || isComplete} sx={{ mb: '12px' }} variant="contained" onClick={() => { isGameComplete() }}>Controleer antwoord</Button>
            <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "text"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar keuzemenu" : (isComplete ? "Verder" : "Overslaan")}</Button>
          </ButtonsContainer>
        </Stack>
      </Box>

      {/* complete dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false); }} secondaryButtonFunc={() => { }} />

      {/* wrong answer dialog */}
      <AlertDialog open={wrongDialogState} handleClose={() => SetWrongDialogState(false)} title="Verkeerd"
        content="Deze foto is niet de juiste"
        primaryButtonText="Opnieuw proberen" secondaryButtonText=""
        primaryButtonFunc={() => { SetWrongDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}