export const ContentType = {
    Text: "text",
    Audio: "audio",
    Video: "video",
    Image: "image",
    Panorama: "panorama",
    Puzzle: "puzzle",
    Tap: "tap",
    BeforeAfter: "beforeafter",
    Quiz: "quiz",
    Share: "share",
    PictureFinder: "picturefinder",
    Multi: "multi",
    Navigate: "navigate",
    Model: "model",
    RouteComplete: "routecomplete"
  };
  