// @mui
import { Box, Stack, Container, Button } from '@mui/material';

// components
import ReactPlayer from 'react-player/lazy';

import { ButtonsContainer, Title, SubTitle, Overline } from '../../../components/content';
import { formatUrl } from "../../../utils/formatUrl";
import "../../../components/video.css";

// ----------------------------------------------------------------------

export default function ContentVideo(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, videoThumbnail } = content;

  function onReady() {
    console.log('onReady');
  }

  function onStart() {
    console.log('onStart');
  }

  function handlePlay() {
    console.log('handlePlay');
  }

  function handlePause() {
    console.log('handlePause');
  }

  function onBuffer() {
    console.log('onBuffer');
  }

  function handleOnPlaybackRateChange() {
    console.log('handleOnPlaybackRateChange');
  }

  function onSeek(number) {
    console.log('onSeek', number);
  }

  function handleEnded() {
    console.log('handleEnded');
    onComplete();
  }

  function handleDuration() {
    console.log('handleDuration');
  }



  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '16px', pt: '8px' }}>
      <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }} spacing={4} textAlign="left" alignItems='center'>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            {fromMulti ?
              <Overline>
                {multiTitle}
              </Overline>
              : null}
            <Title variant="h1">
              {title}
            </Title>
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <ReactPlayer
            width='100%'
            height='auto'
            config={{ attributes: { poster: formatUrl(videoThumbnail) } }}
            url={`${formatUrl(url)}#t=0.001`} // #t=0.001  is hack for video thumbnail on ios browsers 
            style={{ maxWidth: '600px' }}
            playing={false}
            playsinline
            controls
            onReady={() => { onReady(); }}
            onStart={() => { onStart(); }}
            onPlay={() => { handlePlay(); }}
            onPause={() => { handlePause(); }}
            onBuffer={() => { onBuffer(); }}
            onPlaybackRateChange={() => { handleOnPlaybackRateChange(); }}
            onSeek={e => onSeek(e)}
            // onEnded={() => { handleEnded(); }}
            onError={e => console.log('onError', e)}
            onDuration={e => { handleDuration(e); }} />
        </Container>
        <ButtonsContainer>
          <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { onComplete() }}> {fromMulti ? "Terug naar keuzemenu" : "Verder"}</Button>
        </ButtonsContainer>
      </Stack>
    </Box>
  );
}