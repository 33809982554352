import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
// components
import Iconify from '../../../components/iconify';



// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '240px !important',
});

// ----------------------------------------------------------------------

RouteSearch.propTypes = {
  routes: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired,
};

export default function RouteSearch({ routes, onSelection }) {
  return (
    <Autocomplete
      onChange={(event, value) => {console.log(value); onSelection(value);}}
      sx={{ width: 300 }}
      autoHighlight
      popupIcon={null}
      PopperComponent={StyledPopper}
      options={routes}
      getOptionLabel={(route) => route.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Zoek route..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'primary.main' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
