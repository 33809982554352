import React, { useRef, useState, useImperativeHandle, useCallback, useEffect } from "react";
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import PropTypes from 'prop-types';
import { isSafari } from "react-device-detect";

import {
  Wrapper,
  Video,
  Container,
  Overlay,
} from "./styles";

import { Flash } from "../flash";


export default function Camera({ overlaySrc, myRef }) {
  const videoRef = useRef();
  const cameraRef = useRef();
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    cameraRef.current = new CameraPhoto(videoRef.current);

    cameraRef.current.startCamera(FACING_MODES.ENVIRONMENT, { width: 1920, height: 1080 })
      .then(() => {
        console.log('camera started !');
      })
      .catch((error) => {
        console.error('Camera not started!', error);
      });
  }, [])

  useEffect(() => () => {
    console.log("camera cleanup");
    stopCamera();
    videoRef.current = null;
    cameraRef.current = null;
  }, [])

  const takePhoto = useCallback(async () => {

    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    if (videoRef.current && cameraRef.current) {
      const config = {
        sizeFactor: 1,
        imageType: IMAGE_TYPES.JPG,
      };
      setIsFlashing(true);
      videoRef.current.pause();
      await timeout(200);
      const dataUri = await cameraRef.current.getDataUri(config);
      stopCamera();
      return dataUri;
    }

    return undefined;
  }, []);

  function stopCamera() {
    if (cameraRef.current) {
      cameraRef.current.stopCamera()
        .then(() => {
          console.log('Camera stopped!');
        })
        .catch(() => {
          console.log('No camera to stop!');
        });
    }
  }

  useImperativeHandle(myRef, () => ({
    takePhoto
  }), [takePhoto])

  return (
    <Container>
      {
        <Video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          safari = {isSafari}
        />
      }
      <Wrapper>
        <Overlay className={"unselectable unclickable"} src={overlaySrc} />
      </Wrapper>
      <Flash
        flash={isFlashing}
        onAnimationEnd={() => setIsFlashing(false)}
      />
    </Container>
  );
}

Camera.propTypes = {
  overlaySrc: PropTypes.string.isRequired,
  myRef: PropTypes.any,
};

