import PropTypes from 'prop-types';

import { ButtonBase } from '@mui/material';

const TapImageButton = ({ value, x, y, size, image, onClick }) => (

    <ButtonBase style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        width: `${size}%`,
        height: `${size}%`,
        borderRadius: '100%'
    }}
        onClick={() => onClick()}>
        {value ?
            <img
                src={image}
                alt="background"
                className={"unclickable unselectable"}
                loading="lazy"
            />
            :
            null}
    </ButtonBase>
);

TapImageButton.propTypes = {
    value: PropTypes.bool.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default TapImageButton;
