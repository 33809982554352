import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';


const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Flash = styled('div')(({ flash }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0,
  backgroundColor: '#ffffff',
  animation: `${flash ? `${flashAnimation} 400ms ease-out` : 'none'}`,
}));