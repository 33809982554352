// @mui
import { React, useEffect, useRef, useState, useCallback } from 'react'
import { Box, Tab, Typography, Container, IconButton, Snackbar, Alert, Stack } from '@mui/material';
import { useTabContext } from "@mui/lab/TabContext"
import { TabContext, TabList } from "@mui/lab"
import { computeDistanceBetween, computeHeading } from 'spherical-geometry-js';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { AlertDialog } from '../../../components/dialog';
import { Map, Compass } from '../../../components/map';

// ----------------------------------------------------------------------

// custom tab to keep both tabls alive
function TabPanel(props) {
  const {
    children,
    className,
    style,
    value: id,
    ...other
  } = props

  const context = useTabContext()

  if (context === null) {
    throw new TypeError("No TabContext provided")
  }
  const tabId = context.value

  return (
    <div
      className={className}
      style={{
        width: "100%",
        position: "absolute",
        margin: 0,
        padding: 0,
        ...style,
        left: 0,
        top: '49px',
        height: '100%',
        overflow: 'hidden',
        visibility: id === tabId ? "visible" : "hidden",
      }}
      {...other}
    >
      {children}
    </div>
  )
}


TabPanel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};

const CompassDiv = styled('div')((props) => ({
  position: 'relative',
  aspectRatio: '1 / 1',
  display: 'block',
  margin: 'auto',
  [props.theme.breakpoints.only("xs")]: {
    width: '85%'
  },
  [props.theme.breakpoints.only("sm")]: {
    width: '60%'
  },
  [props.theme.breakpoints.up('md')]: {
    width: '40%',
  },
}));

const CompassFillDiv = styled('div')((props) => ({

  [props.theme.breakpoints.only("xs")]: {
    height: '15%'
  },
  [props.theme.breakpoints.only("sm")]: {
    height: '20%'
  },
  [props.theme.breakpoints.up('md')]: {
    height: '25%',
  },
}));


const TextGradient = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: '0px',
  paddingBottom: 'clamp(16px, 16px + 2vh, 48px)',
  paddingLeft: '16px',
  paddingRight: '16px',
  backgroundImage: `linear-gradient(transparent, ${theme.palette.background.appbar})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export default function ContentNavigate(content) {
  const { targetPosition, text, range, route, onComplete } = content;

  const [tabId, setTabId] = useState("tab1");

  const [{ currentPosition, currentHeading }, setCurrentPositionHeading] = useState({ currentPosition: null, currentHeading: 0 });
  const [infoDialogState, setInfoDialogState] = useState(false);

  const [gpsWarningState, setGpsWarningState] = useState(false);
  const [orientationWarningState, setOrientationWarningState] = useState(false);

  const helpText = "Houdt je telefoon plat voor je. Draai tot beide pijltjes dezelfde kant op wijzen. Loop in deze richting om bij de volgende locatie te komen.\n\nSommige telefoons ondersteunen helaas geen kompas, gebruik dan de kaart.";
  const gpsDeniedText = "Je hebt geen locatietoestemming gegeven. Verander deze in de instellingen van je telefoon of browser en herlaad de pagina.";
  const orientationText = "Je hebt geen orientatietoestemming gegeven, Safari moet volledig opnieuw opstarten voordat we dit opnieuw kunnen vragen.";


  const locationWatchId = useRef(null);

  const handleChange = (event, id) => setTabId(id)


  function getDistance(pos1, pos2) {
    if (pos1 && pos2) {
      return computeDistanceBetween(pos1, pos2);
    }
    return -1;

  }

  function getHeading(pos1, pos2) {
    if (pos1 && pos2) {
      return computeHeading(pos1, pos2);
    }
    return 0;
  }

  function getBounds() {
    const positions = [];
    if (route.position && route.position.lat && route.position.lng) {
      positions.push(route.position);
    }
    route.content.map((c) => {
      if (c.targetPosition && c.targetPosition.lat && c.targetPosition.lng) {
        positions.push(c.targetPosition);
      }
      return undefined;
    });

    return positions;
  }

  const listenForLocation = useCallback(() => {

    function updateLocation(rawPosition) {
      const position = {
        lat: rawPosition.coords.latitude,
        lng: rawPosition.coords.longitude,
      };
      const targetHeading = getHeading(position, targetPosition);
      setCurrentPositionHeading({ currentPosition: position, currentHeading: targetHeading });


      const dist = getDistance(position, targetPosition);
      // fallback to range of 15m
      const detectionRange = (range || range === 0) ? range : 15;
      if (dist >= 0 && dist < detectionRange) {
        if (onComplete) {
          onComplete();
        }
      }
    }

    if ("geolocation" in navigator) {
      if (!locationWatchId.current) {
        // get current postiion quickly
        /*     navigator.geolocation.getCurrentPosition((rawPosition) => {
               updateLocation(rawPosition);
               setGpsWarningState(false);
             }, (err) => {
     
               if (err && err.code === err.PERMISSION_DENIED) {
                 // location permission denied
                 setGpsWarningState(true);
               } else if (err && err.code === err.TIMEOUT) {
                 // allowed, simply timed out
               }
             },
               { enableHighAccuracy: true }
             );
             */

        // listen for location changes
        locationWatchId.current = navigator.geolocation.watchPosition((rawPosition) => {
          updateLocation(rawPosition);
          setGpsWarningState(false);
        }, (err) => {
          if (err && err.code === err.PERMISSION_DENIED) {
            // location permission denied
            setGpsWarningState(true);
          }
        },
          { enableHighAccuracy: true }
        );
      } else {
        console.log("location listener already active");
        setGpsWarningState(false);
      }

    } else {
      console.log("Location not available or supported");
      setGpsWarningState(true);
    }
  }, [onComplete, range, targetPosition]);

  // run once at startup
  useEffect(() => {

    navigator.permissions.query({ name: "geolocation" })
      .then((permissionStatus) => {
        // permissionStatus.state = prompt, granted, denied

        // Chrome only
        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'granted') {
            listenForLocation();
          } else {
            setGpsWarningState(true);
          }
        };

        listenForLocation();

      }).catch(() => {
        listenForLocation();
      });

  }, [listenForLocation])

  // cleanup
  useEffect(() => () => {
    console.log("cleanup nagivate");
    if (locationWatchId.current) {
      navigator.geolocation.clearWatch(locationWatchId.current);
      locationWatchId.current = undefined;
    }
  }, []);


  return (<>
    <Box sx={{ overflow: 'hidden', position: 'relative', width: '100%', height: '100%' }}>

      <TabContext value={tabId}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList sx={{ background: (theme) => theme.palette.background.alternative }} value={tabId} variant="standard" onChange={handleChange}>
            <Tab sx={{ width: '50%', maxWidth: 'unset' }} value="tab1" label="Kompas" />
            <Tab sx={{ width: '50%', maxWidth: 'unset' }} value="tab2" label="Kaart" />
          </TabList>
        </Box>
        <TabPanel value="tab1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between' }} >
          <Container sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0', padding: '0 !important', position: 'absolute', top: '0', right: '0', width: '100%' }}>
            <IconButton sx={{ width: '56px', p: '16px', textTransform: 'none', color: '#D5D5D5' }} onClick={() => { setInfoDialogState(true); }} aria-label="help" component="label">
              <Iconify icon="mdi:help-circle-outline" width='100%' height='100%' />
            </IconButton>
          </Container>
          {<CompassDiv>
            <Compass hasPosition={currentPosition && true} headingDegrees={currentHeading} deniedCallback={compassDenied} />
          </CompassDiv>}
          <CompassFillDiv />
        </TabPanel>
        <TabPanel value="tab2">
          <div style={{ height: '100%', width: '100%' }}>
            <Map targetPosition={targetPosition} currentPosition={currentPosition} boundsPositions={getBounds()} />
          </div>
        </TabPanel>
      </TabContext>
      {<TextGradient >
        <Stack >
          <Typography variant="h2" color="primary" sx={{ textAlign: 'center' }}>
            {currentPosition ? formattedDistance() : '?? m'}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: '700px' }}>
            {text}
          </Typography>
        </Stack>
      </TextGradient>}

    </Box>
    {/* Info dialog */}
    <AlertDialog open={infoDialogState} handleClose={() => setInfoDialogState(false)} title="Instructies"
      content={helpText}
      secondaryButtonText="Ik begrijp het"
      secondaryButtonFunc={() => { setInfoDialogState(false) }}
    />
    <Snackbar open={gpsWarningState} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ top: '118px' }}>
      <Alert onClose={() => setGpsWarningState(false)} severity="warning" sx={{ width: '100%', backgroundColor: '#FFF4E4', color: '#6E3F0A', textAlign: 'left', lineHeight: '1.5' }}>
        {gpsDeniedText}
      </Alert>
    </Snackbar>
    <Snackbar open={orientationWarningState && !gpsWarningState} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ top: '118px' }}>
      <Alert onClose={() => setOrientationWarningState(false)} severity="warning" sx={{ width: '100%', backgroundColor: '#FFF4E4', color: '#6E3F0A', textAlign: 'left', lineHeight: '1.5' }}>
        {orientationText}
      </Alert>
    </Snackbar>
  </>
  );

  function compassDenied() {
    if (!orientationWarningState) {
      setOrientationWarningState(true);
    }
  }

  function formattedDistance() {
    const dist = Math.round(getDistance(currentPosition, targetPosition));
    if (dist < 10000) {
      return `${dist} m`;
    } else {
      // km with max 1 decimal
      const km = Math.round((dist / 1000) * 10) / 10;
      return `${km} km`;
    }
  }

}