import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';

import { useState } from 'react';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 2 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {children.length > 0 ?

        // NavItem with children
        <>
          <StyledNavItem
            onClick={handleClick}
            sx={{
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                borderRadius: '6px 6px 0px 0px'
              },
              mb: 1
            }}
            className={window.location.pathname.includes(path) ? 'active' : ''}>

            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
            {open ? <Iconify sx={{ mr: '10px' }} icon="ic:baseline-expand-less" width={22} height={22} /> : <Iconify sx={{ mr: '10px' }} icon="ic:baseline-expand-more" width={22} height={22} />}
            {info && info}
          </StyledNavItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" sx={{ p: 0 }}>
              {item.children.map((child, i, { length }) => (
                <NavSubItem key={child.title} item={child} isLast={i + 1 === length} />
              ))}
            </List>
          </Collapse>
        </>

        :

        // NavItem without children
        <>
          <StyledNavItem
            component={RouterLink}
            to={path}
            sx={{
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
              },
              mb: 1
            }}>

            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

            <ListItemText disableTypography primary={title} />

            {info && info}
          </StyledNavItem>
        </>
      }
    </>
  );
}

NavSubItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool,
};

function NavSubItem({ item, isLast }) {
  const { title, path, info } = item;

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
          borderRadius: isLast ? '0px 0px 6px 6px' : '0px 0px 0px 0px',
        }}
      >
        <ListItemText sx={{ ml: '60px' }} disableTypography primary={title} />

        {info && info}
      </StyledNavItem>
    </>
  );
}
