import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { formatUrl } from "../utils/formatUrl";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
  textAlign: 'center'
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 | Oorlogspad </title>
      </Helmet>

      <Container>
        <StyledContent>
          <Typography variant="h1" paragraph>
            Sorry, pagina niet gevonden!
          </Typography>

          <Typography variant="body1" sx={{ color: 'text.secondary', maxWidth: '600px', m: 'auto' }}>
            Sorry, we kunnen de pagina die u zoekt niet vinden. Misschien heeft u de URL verkeerd getypt? Controleer uw spelling.
          </Typography>

          <Box
            component="img"
            src={formatUrl(`/assets/algemeen/illustrations/illustration_404.svg`)}
            sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)', mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" variant="contained" sx={{ maxWidth: '480px !important', m: 'auto', width: '100%' }} component={RouterLink}>
            Naar homepage
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
